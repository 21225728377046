import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import '../formations-page.css'
import './missions-page.css'
import HelmetWidget from '../../components/helmet';

const MissionDiagnosticTechnique = (props) => {
	var bkgImage = "/images_missions/diagnostic-technique.jpg"
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Mission Diagnostic Technique"
				description="Bénéficiez d'une réalisation de diagnostics techniques immobiliers pour la vente ou la location de votre bien immobilier. Nous vous accompagnons dans la réalisation de diagnostics gaz, amiante, performance énergétique, termite, plomb, électricité, état des risques naturels et technologiques, loi Carrez."
				path="missions/mission-diagnostic-technique"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero">
				<div className="home-hero1 mission-title-div">
					<div className="home-container01">
						<h1 className="home-hero-heading">
							DIAGNOSTIC TECHNIQUE
						</h1>
						<span className="home-hero-sub-heading bodyLarge">

						</span>
					</div>
				</div>
			</div>
			<div className='only_mobile image-mission' style={{ backgroundImage: "url(" + bkgImage + ")" }}></div>
			<div className="main-div-mission">
				<Tabs
					defaultActiveKey="a"
					className="tabs-div"
					justify
				>
					<Tab tabClassName='btn-tab-class' eventKey="diagnostic-immobilier-gaz" title="Gaz">
						<div className="div-content-diag">
							<p>
								Ce diagnostic immobilier est obligatoire si vous souhaitez vendre une maison ou un appartement dont l'installation au gaz
								date d'il y a plus de 15 ans, sous peine de sanctions civiles. Il est effectué dans l'objectif de déterminer si l'installation
								intérieure au gaz (naturel, en citerne ou en bouteilles) est conforme aux normes.
							</p>
							<p>
								<strong>Textes relatifs au diagnostic gaz :</strong>
							</p>
							<p>
								Article 17 - loi 2003-8 du 3 janvier 2003
							</p>
							<p>
								Code de la santé publique, art. L271-4 à L271-6 et L134-6
							</p>
							<p>
								Décret n° 2006-1147 du 14 septembre 2006
							</p>
							<p>
								Arrêté du 6 avril 2007
							</p>
							<p>
								<strong>Validité du diagnostic gaz:</strong>
							</p>
							<p>
								Le diagnostic gaz a une validité de 3 ans.
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="diagnostic-immobilier-amiante" title="Amiante">
						<div className="div-content-diag">
							<p>
								Diagnostique immobilier amiante vise à détecter une éventuelle présence d'amiante. Il doit obligatoirement être effectué
								si le permis de construire de votre immeuble d'habitation, local professionnel ou industriel ou ERP a été délivré avant le
								1er juillet 1997.
							</p>
							<p>
								<strong>Textes relatifs au diagnostic amiante:</strong>
							</p>
							<p>
								Décrets : 07/02/1996, 12/09/1997, 13/09/2001 et 31/05/2002
							</p>
							<p>
								Arrêté du 02/01/2002
							</p>
							<p>
								Norme NFX 46-020
							</p>
							<p>
								Décret n° 2006-761 du 30/06/2006
							</p>
							<p>
								Circulaire interministérielle DGS/2006-271 et 2006-48 UHC/QC1 du 14/06/2006
							</p>
							<p>
								<strong>Validité du diagnostic amiante: </strong>Sans limite
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="diagnostic-immobilier-performance-energetique-DPE" title="Énergétique">
						<div className="div-content-diag">
							<p>
								IL immobilier permet de connaître la classe énergétique (A à G) de votre bien immobilier en déterminant la consommation
								annuelle d'énergie. Il est obligatoire si vous souhaitez
							</p>
							<p>
								- vendre un bien immobilier (depuis le 1er novembre 2006).
							</p>
							<p>
								- louer un bien immobilier (depuis le 1er juillet 2007).
							</p>
							<p>
								- publier une annonce immobilière - depuis le 1er janvier 2011, l'étiquette énergétique est obligatoire.
							</p>
							<p>
								<strong>Textes relatifs au diagnostic DPE :</strong>
							</p>
							<p>
								Directive 2002-91-CE du Conseil
							</p>
							<p>
								Ordonnance n° 2005-655 du 8 juin 2005
							</p>
							<p>
								Décret n° 2010-1662 relatif à l'étiquette énergétique
							</p>
							<p>
								<strong>Validité de diagnostic DPE :</strong>
								10 ans
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="diagnostic-immobilier-termite" title="Termite">
						<div className="div-content-diag">
							<p>
								Le diagnostic immobilier termite permet de détecter les éventuelles dommages causés par les termites et autres insectes
								xylophages.
							</p>
							<p>
								<strong>Textes relatifs au diagnostic termite:</strong>
							</p>
							<p>
								Loi 99-471 du 08/06/1999
							</p>
							<p>
								Décret 2000-613 du 03/07/2000
							</p>
							<p>
								Arrêté du 10/08/2000
							</p>
							<p>
								Norme NF P 03-200
							</p>
							<p>
								Réglementation en cours d'évolution
							</p>
							<p>
								<strong>Validité du diagnostic termite: </strong>6 mois
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="diagnostic_immobilier_plomb" title="Plomb">
						<div className="div-content-diag">
							<p>
								Ce diagnostic immobilier est effectué afin de verifier si les revêtements de votre bien immobilier contiennent du plomb
								et pour déterminer les risques de délabrement du bâti. Il est obligatoire si votre immeuble d'habitation a été construit
								avant le 1er janvier 1949, même si vous souhaitez le louer.
							</p>
							<p>
								<strong>Textes relatifs au diagnostic plomb:</strong>
							</p>
							<p>
								Décret n° 2006-474
							</p>
							<p>
								Arrêtés du 25/04/2006
							</p>
							<p>
								<strong>Validité du diagnostic plomb:</strong>
							</p>
							<p>
								Vente : 12 mois selon conclusion
							</p>
							<p>
								Location : 6 ans
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="diagnostic-immobilier-electricite" title="Electricité">
						<div className="div-content-diag">
							<p>
								Diagnostic immobilier électrique sert à établir si l'installation électrique de votre bien est conforme à la norme et le
								cas échéant permettre une mise à niveau. Il est obligatoire si vous souhaitez vendre un bien dont l'électricité a été installée
								il y a plus de 15 ans.
							</p>
							<p>
								<strong>Textes relatifs diagnostic immobilier électrique:</strong>
							</p>
							<p>
								Décret à paraître
							</p>
							<p>
								<strong>Validité du diagnostic immobilier électrique:</strong>
							</p>
							<p>
								Le diagnostic électrique a une validité de 3 ans.
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="diagnostic-immobilier-etat-risques-naturels-technologiques-ERNT" title="ERNT">
						<div className="div-content-diag">
							<p>
								Comme son nom l'indique, le diagnostique immobilier ERNT (État des Risques Naturels et Technologiques, ) établit les risques naturels et technologiques, mais une déclaration
								obligatoire des sinistres survenus dans le bien immobilier en fait également partie. Il est obligatoire depuis le 1e juin
								2006 si vous souhaitez vendre ou louer un bien immobilier.
							</p>
							<p>
								<strong>Textes relatifs au diagnostic ERNT:</strong>
							</p>
							<p>
								Loi du 30/07/2003, art. L125-5 et R125-23 à 27 du Code de l'Environnement
							</p>
							<p>
								Ordonnance n° 20005-655 du 08/06/2005
							</p>
							<p>
								<strong>Validité du diagnostic ERNT:</strong>
							</p>
							<p>
								Maximum 6 mois avant la vente
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="diagnostic-immobilier-loi-Carrez" title="Loi Carrez">
						<div className="div-content-diag">
							<p>
								Ce diagnostic immobilier permet de connaître le métrage exact de la surface privative de votre bien immobilier et doit obligatoirement
								être effectué dans les immeubles d'habitation ou locaux professionnels qui appartiennent à une copropriété verticale ou horizontale.
							</p>
							<p>
								<strong>Textes relatifs au diagnostic loi Carrez:</strong>
							</p>
							<p>
								Loi 96-1107 du 18/12/1996
							</p>
							<p>
								Décret 97-53 du 23/05/1997
							</p>
							<p>
								<strong>Validité du diagnostic loi Carrez:</strong>
								Sans limite
							</p>
						</div>
					</Tab>
				</Tabs>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default MissionDiagnosticTechnique;