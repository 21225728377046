import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationSecourisme = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Secourisme"
				description="La formation Secourisme a pour objectif de doter les participants des connaissances et compétences nécessaires pour intervenir efficacement en cas d'urgence. Elle comprend des modules tels que la réanimation cardio-respiratoire, l'utilisation du défibrillateur, ainsi que des séances pratiques."
				path="formations/formation-secourisme"
			>
			</HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION SECOURISME</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							nomFormation="Secourisme"
							titre="Sauveteur Secouriste du Travail (SST) - Formation Initiale"
							duree="2 jours / 14 heures"
							prix="360 € HT"
							objectif={[
								"Acquérir les connaissances en matière de secourisme pour protéger ou secourir toute personne au sein de l’entreprise.",
								"Être capable d’intervenir sur un lieu d’accident, de secourir la victime et préparer l’arrivée des secours.",
								"Préparer à la qualification de Sauveteur Secouriste du Travail (SST)."
							]}
							participants={[
								"L’ensemble du personnel désigné pour donner les premiers soins : les salariés, le personnel d’encadrement, les équipiers de sécurité en ERP-IGH, les gardiens et agents de surveillance."
							]}
							programme={[
								"Le sauvetage secourisme du travail.",
								"Le rôle du Sauveteur Secouriste du Travail (SST).",
								"Protéger et prévenir : rechercher les dangers persistants.",
								"Examiner la victime.",
								"Alerter : qui et comment prévenir ?",
								"Secourir : les gestes d’urgence selon les cas d’accidents.",
								"Réanimation cardio-respiratoire, formation à l’utilisation du défibrillateur.",
								"Risques particuliers d’accidents.",
								"Séances pratiques.",
								"Évaluation et contrôle des connaissances."
							]}
						/>
						<AccordionBase
							numberKey="1"
							nomFormation="Secourisme"
							titre="Sauveteur Secouriste du Travail (SST) - Recyclage"
							duree="1 jour / 7 heures"
							prix="180 € HT"
							objectif={[
								"Perfectionner et entretenir les connaissances en matière de secourisme, utilisables aussi bien dans la vie professionnelle que sociale.",
								"Prolonger la qualification de Sauveteur Secouriste du Travail."
							]}
							participants={[
								"L’ensemble du personnel, le personnel d’encadrement, les services de sécurité en ERP-IGH, les gardiens et agents de surveillance."
							]}
							preRequis={[
								"Etre titulaire du certificat de Sauveteur Secouriste du Travail."
							]}
							programme={[
								"Rappel des principales fonctions du Sauveteur Secouriste du Travail (SST).",
								"Réanimation cardio-respiratoire, formation à l’utilisation du défibrillateur.",
								"Séances pratiques.",
								"Contrôle des connaissances."
							]}
						/>
						<AccordionBase
							numberKey="3"
							nomFormation="Secourisme"
							titre="Manutention Manuelle - Gestes et Postures de Travail"
							duree="1 jour / 7 heures"
							prix="190 € HT"
							objectif={[
								"Connaître les techniques, théoriques et pratiques, de manutention manuelle.",
								"Aborder les notions d’analyse de poste de travail en vue de leur optimisation (sécurité et performance)."
							]}
							participants={[
								"L’ensemble du personnel des entreprises, notamment les personnes affectées à des travaux de manutention manuelle et celles effectuant occasionnellement de la manutention ou du transport de matériel."
							]}
							preRequis={["Aptitude physique au port des charges"]}
							programme={[
								"Les accidents de manipulation et de transport manuel.",
								"Notions d’anatomie.",
								"Principes de sécurité physique.",
								"Application aux matériaux manutentionnés.",
								"Approche de l’analyse des risques d’un poste de travail.",
								"Travaux pratiques."
							]}
							pedagogie="Alternance d’exposés théoriques et d’exercices pratiques, durant lesquels les stagiaires manipulent diverses charges. Délivrance d’une attestation de formation. Participants : maximum 10."
							evaluationValidation="Délivrance d’une attestation de formation."
						/>
					</Accordion>
					<FooterFormation
						nomFormation="Secourisme"
						downloadLink={"SECOURISME.pdf"}
					></FooterFormation>
				</div>
				<FormationText
					objectif="Le sauveteur secouriste du travail doit être capable d’intervenir efficacement face à une situation d’accident et, dans le respect de l’organisation de l’entreprise et des procédures spécifiques fixés en matière de prévention, de mettre en application ses compétences au profit de la santé et sécurité au travail."
					downloadLink="SECOURISME.pdf"
					nomFormation="Secourisme"
					tauxReussite="94 %"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationSecourisme