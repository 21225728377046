import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'


import '../formations-page.css'
import './missions-page.css'
import HelmetWidget from '../../components/helmet';

const MissionAssistanceTravauxUrgence = (props) => {
	var bkgImage = "/images_missions/assistance-travaux-urgence.jpg"
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Mission Assistance Travaux d'urgence"
				description="Béneficiez de notre expertise dans l'utilisation des drones en situation d'urgence. Nous vous accompagnons dans la réalisation de travaux d'urgence et de secours."
				path="missions/mission-assistance-travaux-urgence"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero">
				<div className="home-hero1 mission-title-div">
					<div className="home-container01">
						<h1 className="home-hero-heading">
							ASSISTANCE TRAVAUX D'URGENCE
						</h1>
						<span className="home-hero-sub-heading bodyLarge">
						</span>
					</div>
				</div>
			</div>
			<div className='only_mobile image-mission' style={{ backgroundImage: "url(" + bkgImage + ")" }}></div>
			<div style={{ height: 'auto', minHeight: '60vh', width: '100%' }}>
				<div id="corpsUnique">
					<h2>
						L'UTILISATION DES DRONES EN SITUATION D'URGENCE
					</h2>
					<p>
						Couramment utilisés à des fins militaires, les drones sont plus légers que les avions conventionnels et sont contrôlables
						à distance. Ils peuvent, par conséquent, être utilisés à d’autres fins que pour des frappes militaires.
					</p>
					<p>
						En effet, un drone peut être utilisé pour transporter du matériel de secours d’urgence, sur des zones de sinistre.
					</p>
					<p>
						Lors d’une catastrophe, l’un des problèmes les plus cruciaux reste celui de l’accessibilité. Les sauveteurs se retrouvent
						très souvent dans des situations d’inaccessibilité physique face aux personnes à secourir sur leur lieu d’accident. Divers
						facteurs peuvent en être la cause : l’infrastructure de transport du lieu sinistré pouvant être endommagé et donc difficile
						d’accès, ou autre…
					</p>
					<p>
						Dans ces moments cruciaux, les drones s’avérèrent être des plus utiles. L’aide fournie par le biais d’un drone pourrait,
						par exemple, être liée à la position GPS des personnes à secourir. Si une personne appelle ou envoie un message texte à partir
						d’un endroit éloigné, qui a été frappé par une catastrophe, le drone serait alors capable de cartographier la signature GPS
						de l’appareil pour ensuite être utilisé afin de transporter des vivres, des médicaments et toute autre sorte de matériels.
					</p>
					<p>
						Une autre difficulté rencontrée lors d'une catastrophe est la question du diagnostic de la situation. A titre d'exemple,
						lors du tsunami japonais en 2011, il avait été particulièrement difficile pour les équipes d’intervention d’établir un diagnostic
						fiable de la situation sur place, compte tenu de l’ampleur des dégâts causés par la vague et de la mauvaise qualité de la
						cartographie.
					</p>
					<p>
						Les drones à vocation humanitaire visent justement à améliorer ce diagnostic grâce à des prises de vues aériennes, à l'élaboration
						de cartes et à la mesure précise de la radioactivité ou des conditions météorologiques. Contrairement à des hélicoptères
						ou des avions, ces petits appareils sont capables d'atterrir et de décoller sur des terrains difficiles, en totale autonomie.
					</p>
				</div>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default MissionAssistanceTravauxUrgence;