import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';


const FormationDiagnostiqueur = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Diagnostiqueur"
				description="La formation Risque Électrique a pour finalité de doter les participants des connaissances et compétences indispensables pour occuper le poste d'Exécutant Électrique. Structurée sur plusieurs niveaux, elle aborde les aspects de réglementation, de sécurité électrique, et de mesures de protection."
				path="formations/formation-diagnostiqueur"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION DIAGNOSTIQUEUR</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
				<Accordion defaultActiveKey="0">
						<AccordionBase
							numberKey="0"
							titre="FORMATION METIER | CYCLE 1 / CYCLE 2"
							nomFormation="Diagnostiqueur"
							duree="Cycle 1 : 41 jours 287 heures | Cycle 2 : 43 jours 301 heures"
							prix="3 790,00 € HT"
							objectif={[
								"Obtenir la certification obligatoire à l’exercice du métier de diagnostiqueur(euse) technique immobilier.",
								"Appliquer la méthodologie de diagnostic",
								"Rédiger les rapports de diagnostics en conformité avec la réglementation en vigueur",
								"Définir le besoin du client et le conseiller"
							]}
							participants={[
								"Tout public répondant aux pré-requis ci-dessous",
							]}
							preRequis={[
								"Pré-requis communs au cycle 1 et 2 : Maîtrise de la langue française (lu, écrit, parlé)",
								"Pré-requis spécifiques au cycle 1 : Bac +2 minimum dans le domaine des techniques du bâtiment ou 3 ans d’expériences comme technicien / agent de maitrise dans le domaine des techniques du bâtiment",
							]}
							programme={[
								"Modules visés par une certification de personnes (dans un centre indépendant accrédité COFRAC) : ",
								"Constat des Risques d’Expositions au Plomb (C.R.E.P)",
								"Diagnostic Amiante",
								"Diagnostic termites",
								"Diagnostic de Performance Énergétique (DPE)",
								"Etat de l’installation intérieure Gaz",
								"Etat de l’installation intérieure Electrique",
								"Modules ne nécessitant pas de certification : ",
								"Loi Carrez",
								"ERP",
								"Module complémentaire : ",
								"Contrôle des assainissements collectifs et non collectifs"
							]}
						></AccordionBase>
					</Accordion>
					<FooterFormation
						nomFormation="Diagnostiqueur"
						downloadLink={"DIAGNOSTIC.pdf"}
					></FooterFormation>
				</div>
				<FormationText
					objectif="Obtenir la certification obligatoire à l’exercice du métier de diagnostiqueur(euse) technique immobilier. Appliquer la méthodologie de diagnostic. Rédiger les rapports de diagnostics en conformité avec la réglementation en vigueur. Définir le besoin du client et le conseiller."
					downloadLink="DIAGNOSTIC.pdf"
					nomFormation="Diagnostiqueur"
					tauxReussite="N / A"
					isDiagnostiqueur={true}
					showEvalPeda={false}
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationDiagnostiqueur