export const checkInput = (e) => {
	// Si ce n'est pas un chiffre ou un +, on empêche l'entrée
	var key = e.keyCode || e.charCode;
	var length = e.target.value.length;
	var notInt = e.key !== "0" && e.key !== "1" && e.key !== "2" && e.key !== "3" && e.key !== "4" && e.key !== "5" && e.key !== "6" && e.key !== "7" && e.key !== "8" && e.key !== "9";
	var usedKey = e.key === "+" || key == 8 || key == 46;
	var maxLength = e.target.value.toString().startsWith("+") ? 12 : 10;
	if ((notInt || length >= maxLength) && !usedKey) {
		e.preventDefault();
	}
}