import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import '../formations-page.css'
import './missions-page.css'
import HelmetWidget from '../../components/helmet';

const MissionExpertiseConseil = (props) => {
	var bkgImage = "/images_missions/expertise-conseil.jpg"
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Mission Expertise Conseil"
				description="L'expertise immobilière unique, vous aide à un instant T, à éclairer et décrire votre problématique. Ce type d'expertise vous permet d'éclairer et de sécuriser vos décisions aux plans techniques et/ou économiques. Il peut s'agir d'expertiser la valeur de votre maison ou encore d'analyser les causes de désordres et de dégager les responsabilités. L'expertise immobilière unique peut être une phase préalable à des missions qui nécessitent un accompagnement dans la durée."
				path="missions/mission-expertise-conseil"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero">
				<div className="home-hero1 mission-title-div">
					<div className="home-container01">
						<h1 className="home-hero-heading">
							EXPERTISE CONSEIL
						</h1>
						<span className="home-hero-sub-heading bodyLarge">
							L'expertise immobilière unique, vous aide à un instant T, à éclairer et décrire votre problématique. Ce type d'expertise vous permet d'éclairer et de sécuriser vos décisions aux plans techniques et/ou économiques. Il peut s'agir d'expertiser la valeur de votre maison ou encore d'analyser les causes de désordres et de dégager les responsabilités. L'expertise immobilière unique peut être une phase préalable à des missions qui nécessitent un accompagnement dans la durée.
						</span>
					</div>
				</div>
			</div>
			<div className='only_mobile image-mission' style={{ backgroundImage: "url(" + bkgImage + ")" }}></div>
			<div className="main-div-mission">
				<Tabs
					defaultActiveKey="a"
					className="tabs-div"
					justify
				>
					<Tab tabClassName='btn-tab-class' eventKey="avis" title="Avis technique et/ou économique avant acquisition">
						<div className="div-content">
							<p>
								BureauPreventicas éclaire et sécurise les décisions d'achat de logements en proposant une
								<strong> expertise immobilière avant acquisition</strong>.
							</p>
							<p>
								<strong>L'avis technique </strong>
								permet notamment de vérifier si le bien en vue (appartement ou maison) est exempt de désordres, malfaçons ou sinistres.
							</p>
							<p>
								L'
								<strong>avis économique </strong>
								permet entre autres de s'assurer que le prix de vente correspond bien à la véritable valeur du bien et de chiffrer les travaux
								nécessaires (simple remise aux normes des équipements électriques et/ou chauffage, rénovation, agencements, agrandissement,
								surélévation).
							</p>
							<h3>
								L'expertise immobilière pour bien préparer son achat : maison, pavillon, villa, appartement...
							</h3>
							<p>
								Ces avis peuvent être demandés pour tout type de biens immobiliers : maison, villa, pavillon, appartement, chalet, château,
								loft, résidence principale ou secondaire, investissement locatif...
							</p>
							<p>
								La maison que nous aimerions acheter est elle une bonne affaire ?
							</p>
							<p>
								Le chauffage, l'électricité sont-ils aux normes ? La toiture est-elle bonne ? Combien de travaux de rénovation faut-il prévoir
								?
							</p>
							<p>
								Nous avons remarqué des fissures sur la façade. Y a-t-il un risque d'effondrement ? Faut-il provisionner de lourds travaux
								?
							</p>
							<p>
								L'agence me pousse à signer le compromis de vente. Mais j'ai des doutes sur la véritable valeur de l'appartement.
							</p>
							<p>
								Nous aimerions abattre ou ouvrir un mûr porteur, déplacer un escalier. Est-ce possible ? Combien cela va-t-il coûter ?
							</p>
							<p>
								Quel est le montant les mises aux normes et agencement que nous aimerions faire ? Quel est le juste prix, pour faire une
								offre ?
							</p>
							<h3>
								S'assurer des qualités techniques du bien
							</h3>
							<p>
								L'évaluation des qualités techniques d'une maison ou d'un appartement est indispensable si l'on veut savoir si l'achat envisagé
								est une bonne affaire. L'avis technique avant acquisition permet ainsi d'identifier, de comprendre et prévenir toute pathologie
								pouvant affecter l'intégrité et la solidité du logement. Cela passe, par exemple, par une analyse des causes et de l'évolution
								probable de fissures courant sur des mûrs.
							</p>
							<h3>
								Un avis économique fiable et indépendant
							</h3>
							<p>
								L'avis technique vous a permis de vous assurer qu'il n'y avait pas de signes avant coureurs d'une dégradation importante.
								Et maintenant que vous connaissez l'état technique de votre future acquisition, vous aimerez peut-être savoir si le prix
								de vente correspond bien à la réalité du marché. Après tout, ce n'est pas parce que le bien est sain, qu'il doit être hors
								de prix ! L'avis économique permet de dégager la valeur marchande du bien immobilier, c'est à dire, le juste prix auquel
								il pourrait être acheté ou vendu, dans des conditions normales de libre marché, compte tenu, notamment des travaux nécessaires
								(simple remise aux normes des équipements électriques et/ou chauffage, rénovation, agencements, agrandissement, surélévation).
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="assistance" title="Assistance réception d'une maison ou de travaux">
						<div className="div-content">
							<p>
								BureauPreventicas propose aux particuliers une expertise technique unique. L'objectif est de les
								<strong> aider à bien réceptionner une maison </strong>, un appartement, des travaux ou un chantier.
							</p>
							<h3>
								Expertise immobilière : bien réceptionner une maison, un appartement, des travaux
							</h3>
							<p>
								Les constructions et les rénovations de biens immobiliers ne sont pas une mince aventure. Nous vous assistons afin d'éclairer
								et sécuriser des
								<strong> Contrats de Construction de Maison Individuelle </strong>
								(CCMI) ou de
								<strong> Vente en l’État Futur d'Achèvement </strong>
								(VEFA), autrement appelés achat sur plan.
							</p>
							<p>
								Mon constructeur est pressé de facturer et solder le chantier mais la qualité et la conformité ne sont pas garanties
							</p>
							<p>
								La livraison de mon appartement est sans cesse repoussée et le promoteur ne répond plus au téléphone
							</p>
							<p>
								Je constate plein de malfaçons et d'erreurs, dans ma construction. Dois-je refuser la réception ?
							</p>
							<p>
								Je souhaite faire des réserves, lors de la réception de ma maison... mais le constructeur m'a averti qu'il refuserait de
								me remettre les clefs si je ne débloquais pas les 5% du dépôt de garantie
							</p>
							<h3>
								Anticipez, décelez et surmontez les éventuels problèmes
							</h3>
							<p>
								L'entreprise, maître d'œuvre, vous propose une maison selon un catalogue et (le plus souvent), fait effectuer les travaux
								par des sous-traitants... Mais le chantier prend plus de temps que prévu et les travaux sont mal faits, non-conformes ou
								inachevés. Vous achetez un appartement sur plan... mais la construction est loin d'être conforme au cahier des charges et
								le constructeur nie les évidences et refuse de vous parler. La liste de problèmes est longue... mais, rassurez-vous, BureauPreventicas
								vous permet d'anticiper, de déceler et d'atténuer les obstacles qui nuisent à votre projet immobilier !
							</p>
							<h3>
								Malfaçons, non conformité : mieux résister à la pression des professionnels
							</h3>
							<p>
								En cas de litige, la pression des professionnels du bâtiment est souvent lourde à supporter. Certains prennent un malin
								plaisir à vous induire en erreur pour pouvoir abuser de votre situation, exerçant un véritable chantage, sur les délais.
								Ils peuvent profiter de vos manques de connaissance des lois et des techniques car vous n'êtes pas un spécialiste. Souscrire
								à un CATEC vous protège : l'assistance d'un expert indépendant aide à rétablir l'équilibre entre vous et les professionnels
								; son savoir faire et ses expériences vous permet d'éviter les pièges.
							</p>
							<h3>
								Réception d'une maison, d'un appartement ou de travaux : pourquoi être assisté d'un expert immobilier ?
							</h3>
							<p>
								Notre expertise vous garantit :
							</p>
							<p>
								une analyse des documents techniques contractuels relatifs à l'acquisition ou aux travaux
							</p>
							<p>
								un examen des non-conformités aux documents ou aux règles de l'art
							</p>
							<p>
								une assistance pour la rédaction du procès-verbal contradictoire (le jour de la réception) ou l'établissement d'une liste
								de non-conformités complémentaires (après réception).
							</p>
							<p>
								un chiffrage estimatif global, si nécessaire, par l'expert des malfaçons
							</p>
							<p>
								l'engagement de la responsabilité professionnelle de BureauPreventicas
							</p>
							<p>
								En cas de litige non résolu et porté devant les tribunaux,  BureauPreventicas pourra également vous proposer une assistance
								juridictionnelle (art. 161 CPC), dans le cadre de la procédure.
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="expertise-tech" title="Expertise technique bâtiment/construction">
						<div className='div-content'>
							<p>
								BureauPreventicas donne des avis ponctuels, aux plans technique, économique et juridique, afin d'éclairer vos décisions,
								d'identifier les causes de vos problèmes, estimer des solutions réparatrices de principe et dégager des responsabilités si
								nécessaire.
							</p>
							<h3>
								Expertise immobilière technique : désordres, malfaçons, sinistres, pathologies du bâtiment...
							</h3>
							<p>
								Nos interventions concernent divers biens immobiliers (maison, appartement, pavillon, villa, chalet, château...). Nous pouvons
								vous donner un avis, dès lors que vous souhaitez évaluer, comprendre, interpréter des
								<strong> désordres, malfaçons, sinistres</strong>, afin de prendre toutes les mesures qui s'imposent.
							</p>
							<p>
								Je constate des traces humides et des infiltrations, par temps de pluie, au sous-sol/rez-de-chaussée de ma maison
							</p>
							<p>
								Notre appartement est mal aéré. Les mûrs se noircissent d'année en année et l'air est de moins en moins respirable.
							</p>
							<p>
								Suite à un incendie, l'expert de la compagnie d'assurance sous estime le montant de mon préjudice.
							</p>
							<p>
								Des fissures verticales sont apparues dans l'angle de ma maison de je crains pour la sécurité de ma famille.
							</p>
							<p>
								J'ai commandé des travaux d'agrandissement, de mon pavillon, à une entreprise, mais les travaux sont faits en dépit du bon
								sens et n'avancent pas. Je crains d'avoir trop payé.
							</p>
							<h3>
								Trouver l'origine d'un problème
							</h3>
							<p>
								Champignons, traces de moisissures. Les infiltrations et l'humidité excessive sont de sérieux ennemis pour votre maison
								(en particulier poutres et structures bois) mais surtout pour vos poumons et vos bronches. Et que dire des fissures qui s'étendent
								le long de vos sols, façades et plafonds !
							</p>
							<p>
								En identifiant les causes de vos problèmes, vous pouvez prévoir leur évolution. Notre expertise technique vous permet, ainsi,
								de vérifier que l'intégrité du bâtiment et/ou la sécurité des personnes ne sont pas menacées, stopper des désordres évolutifs,
								prévenir des dégâts ou sinistres majeurs et coûteux.
							</p>
							<h3>
								Envisager et chiffrer les solutions réparatrices
							</h3>
							<p>
								Le carrelage de votre cuisine a été posé sur un support inadapté et/ou non-hydrofuge et le sol, soufflé, se décolle, rendant
								cette pièce impraticable. Vos circuits d'eau, mal isolés, les températures hivernales les ont fait exploser. Le mal est fait
								et vous souhaitez un retour à une situation normale. Cela est possible, grâce à un avis technique ponctuel, qui vous permet
								d'identifier les causes et les responsabilités, de trouver des solutions adaptées à vos problèmes et d'estimer le coût des
								réparations.
							</p>
							<h3>
								Dégager des responsabilités
							</h3>
							<p>
								Vos travaux souffrent de multiples malfaçons ou non-conformités. Mais au dire de l'artisan, qui les a sous-traités, tout
								est parfait ! Établir la faute d'un professionnel, afin que ce dernier corrige ces erreurs, peut passer par un avis technique
								ponctuel. Le rapport d'expertise vous permet également d'obtenir réparation des préjudices subis, devant les tribunaux si
								aucune solution amiable n'est trouvée.
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="estimation" title="Estimation prévisionnelle de travaux">
						<div className='div-content'>
							<p>
								Indépendant des acteurs de la construction et de la rénovation, BureauPreventicas propose aux maîtres d'ouvrage non professionnels
								un chiffrage estimatif de travaux. L'objectif est de les aider à obtenir les meilleurs prix, en proposant, sans mettre en
								relation, des conseils économiques et techniques. Bref, il s'agit notamment de leur faire réaliser de réelles économies!
							</p>
							<h3>
								Estimation prévisionnelle de travaux : évaluation du coût réel de travaux
							</h3>
							<p>
								Rénovation d'un appartement, extension d'une villa, amélioration du confort de vie... Mille et une raisons peuvent motiver
								le lancement d'un chantier. Voilà pourquoi nos interventions concernent tous types de travaux, des plus simples, aux plus
								complexes : aménagement de combles, carrelage, charpente, chauffage, climatisation, couverture, électricité, énergies renouvelables,
								extension, gros œuvre, maçonnerie, isolation...
							</p>
							<p>
								J'ai demandé plusieurs devis pour construire une véranda mais les prix vont de 1 à 5 !
							</p>
							<p>
								Rénover la façade de notre maison couterait 15.000 euros, ce qui nous semble beaucoup
							</p>
							<p>
								La facture finale de l'artisan est salée. Pouvons-nous la contester ?
							</p>
							<p>
								Notre demande a mal été comprise et les travaux ont été bâclés
							</p>
							<p>
								Les malfaçons sont nombreuses et j'ai besoin d'aide pour formuler les réserves
							</p>
							<h3>
								Phase 1 : Avant Projet Sommaire - bénéficier d'un chiffrage estimatif global
							</h3>
							<p>
								Vous avez un projet de rénovation, mais ne savez pas combien les travaux vont coûter. Ce paramètre est pourtant important
								pour savoir, d'une part si votre budget est adapté et d'autre part pour consulter votre banquier. Alors, avant de foncer
								bille en tête et de consulter les artisans, dont les devis peuvent aller du simple au triple, pensez à demander un chiffrage
								estimatif de principe, des travaux (pour s'assurer qu'ils chiffrent tous bien la même chose).
							</p>
							<p>
								En établissant un descriptif global des travaux envisagés, ainsi qu'une estimation, par corps d'état, l'Avant Projet Sommaire
								(APS) vous permet d'avoir une meilleure idée du coût des travaux et donc d'éviter les mauvaises surprises.
							</p>
							<h3>
								Phase 2 : Dossier de Consultation des entreprises - comparer et faire de réelles économies
							</h3>
							<p>
								Grâce à l'Avant Projet Sommaire de BureauPreventicas, vous connaissez l'enveloppe globale estimée des travaux envisagés.
								Il vous faut ensuite sélectionner les artisans. Mais, leur propositions sont souvent différentes (qualité des matériaux,
								temps de réalisation...) et il est difficile de comparer leurs prestations et encore moins leur prix! Demandez un Dossier
								de Consultation des entreprises (DCE) !
							</p>
							<p>
								Le DCE permet de comparer ce qui est comparable. Il consiste à affiner le besoin du maitre d'ouvrage et à le formaliser
								précisément dans un cahier des charges. Ainsi, au travers d'une analyse objective des offres, Il facilite non seulement la
								sélection des entreprises mais également les négociations avec elles.
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="mediation" title="Médiation et Assistance judiciaire (Gestion de litige)">
						<div className="div-content">
							<h3>
								Expertise – conseils
							</h3>
							<p>
								Notre entreprise vous éclaire et sécurise vos décisions d'achat de bien immobilier en vous proposant une expertise générale
								du bâtiment avant achat. L'avis technique permet notamment de vérifier si le bien que vous souhaitez acquérir (appartement,
								maison, locaux professionnels) est exempt de désordres identifiable, malfaçons ou sinistres.
							</p>
							<p>
								Le rapport servira de support pour obtenir d’entreprises spécialisées le chiffrage des travaux nécessaires. Une estimation
								économique est possible suivant la nature des travaux à envisager pour les mises en sécurité ou conformité nécessaires (remise
								aux normes des équipements électriques, remise aux normes des équipements gaz, rénovation de structure, charpente et couverture,
								...).
							</p>
							<h3>
								Médiation.
							</h3>
							<p>
								La médiation consiste à rechercher avec les intervenants concernés en conflit la solution du litige et à rechercher une
								solution amiable plutôt que d’engager l’affaire sur un terrain judiciaire, financièrement coûteux dont l’issue reste souvent
								incertaine
							</p>
							<h3>
								Assistance judiciaire.
							</h3>
							<p>
								Si le litige persiste avec un entrepreneur suite à une contestation sur les travaux réalisés, les malfaçons, les retards
								de livraisons, les montants facturés ou avec un voisin.
							</p>
							<p>
								- Nous assistons votre avocat dans l’étude des pièces techniques du dossier
							</p>
							<p>
								- Nous élaborons conjointement des Dires
							</p>
							<p>
								- Nous participons aux accrédités judiciaires réalisés à votre domicile.
							</p>
							<h3>
								Déroulement de l’Expertise
							</h3>
							<p>
								Notre intervention se déroule suivant la chronologie suivante
							</p>
							<h3>
								1. Visite de la construction
							</h3>
							<p>
								Communication des plans de la construction, des documents contractuels, devis, factures, Procès verbal de réception, courriers,
								mises en demeure, marchés d’entreprises et de Maîtrise d’œuvre.
							</p>
							<h3>
								2. État des lieux
							</h3>
							<p>
								Description de la construction, des environnants et relevé exhaustif des matériaux mis en œuvre
							</p>
							<h3>
								3. Votre réclamation
							</h3>
							<p>
								Fournir les éléments susceptibles d’appréhender l’origine du sinistre :
							</p>
							<p>
								- Date de survenance
							</p>
							<p>
								- Matérialité des désordres,
							</p>
							<p>
								- Les dommages consécutifs.
							</p>
							<h3>
								4.  Relevé des désordres
							</h3>
							<p>
								- Relevé détaillé avec photos
							</p>
							<p>
								- Configuration des lieux
							</p>
							<h3>
								5.  Analyse des désordres
							</h3>
							<p>
								- Nos compétences techniques permettent d’analyser le désordre et d’en déterminer l’origine.
							</p>
							<h3>
								6.  Solution réparatoire
							</h3>
							<p>
								Des solutions de réparations pourront être adoptées en fonction de la cause du désordre sur la base d’un descriptif de travaux
								de réfection.
							</p>
							<p>
								Des investigations complémentaires pourront être envisagées suivant la nature du sinistre.
							</p>
							<p>
								Une première évaluation à dire d’expert sera établie mais elle sera toujours affinée avec des devis d’entreprises.
							</p>
							<p>
								De même les dommages consécutifs ( exemple : réfection des peintures, revêtements carrelages, reprise des enduits extérieurs,
								enduits de piscine… ) feront l’objet d’une estimation chiffrée complétée par un devis d’entreprise .
							</p>
							<h3>
								7.  Le rapport d’expertise
							</h3>
							<p>
								Le rapport d’expertise technique sera établi sur la base des éléments communiqués lors de notre intervention complété par
								:
							</p>
							<p>
								- Les constats opérés sur les lieux,
							</p>
							<p>
								- L’analyse des causes et leur éventuelle évolution
							</p>
							<p>
								- Les solutions réparatoires envisagées pour remédier au désordre
							</p>
							<p>
								- Les responsabilités éventuelles
							</p>
							<p>
								- Avis sur les travaux à entreprendre
							</p>
							<p>
								- Analyse des devis présentés.
							</p>
							<h3>
								8.  Expertise d’Assuré après sinistre
							</h3>
							<p>
								Nous nous engageons à vous assister après un sinistre ( dégât des eaux, fissures, infiltrations, engorgement des systèmes
								d’assainissement, infiltrations par toiture ou sur réseaux encastrés, tassement des fondations, effondrement, tempêtes, incendie…).
							</p>
							<p>
								En notre qualité d’Expert d’Assuré, nous élaborons avec l’Expert d’assurances l’indemnité transactionnelle qui sera proposée
								par la Compagnie d’Assurances.
							</p>
							<p>
								En effet, l’Expert d’Assurances établit généralement un rapport avec une proposition financière sur la base la plus réduite
								qui n’est transmis qu’à sa Compagnie.
							</p>
							<p>
								Nous intervenons aussi pour rétablir un équilibre entre les oppositions lors de l’expertise amiable contradictoire.
							</p>
						</div>
					</Tab>
				</Tabs>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default MissionExpertiseConseil;