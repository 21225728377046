import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useState } from 'react';


import './navbar.css';


function MyNavbar() {
	const history = useHistory();
	function handleClick(path) {
		history.push("/" + path);
		// Scroll to top
		window.scrollTo(0, 0);
	}

	const [show, setShow] = useState(false);
	const showDropdownFormation = (e) => {
		setShow(!show);
	}
	const hideDropdownFormation = e => {
		setShow(false);
	}

	const [showMission, setShowDropdownMission] = useState(false);
	const showDropdownMission = (e) => {
		setShowDropdownMission(!showMission);
	}

	const hideDropdownMission = e => {
		setShowDropdownMission(false);
	}

	function handleFormationClick(e) {
		if (e.target.id != "collasible-nav-dropdown") return;
		history.push("/formations");
		window.scrollTo(0, 0);
	}


	function call() {
		// Si sur mobile, tenter l'appel sinon copier le numéro
		if (window.innerWidth <= 768) window.open('tel:0143932273', '_self');
		else navigator.clipboard.writeText('0143932273');
	}

	return (
		<Navbar fixed="top" expand="lg" bg="white" className='navbar'>
			<Navbar.Toggle
				className="ms-3"
				aria-controls="basic-navbar-nav"
			/>
			<Navbar.Brand id="brandLogo" className="ms-3 navbar_logo_div" onClick={() => handleClick("")}>
				<img className="logo_img" alt="logo" />
			</Navbar.Brand>
			<div className='num_mobile'>
				<Navbar.Text>
					<a onClick={call} className="phone-text">
						<FontAwesomeIcon icon={faPhone} /> 01 43 93 22 73
					</a>
				</Navbar.Text>
				<Navbar.Text>
					<a href="https://wa.me/33760403446" className="phone-text d-flex flex-row">
						<img src="/external/whatsapp.png" alt="whatsapp" className="whatsapp-icon" />
						07 60 40 34 46
					</a>
				</Navbar.Text>
				<Navbar.Text>
					<a onClick={() => handleClick("contact")} className="phone-text">
						@FORMATION
					</a>
				</Navbar.Text>
			</div>
			<Navbar.Collapse id="basic-navbar-nav" className="justify-content-evenly ms-5">
				<Nav>
					<Nav.Link className='only_mobile' onClick={() => handleClick("")}>BUREAU PREVENTICAS</Nav.Link>
					<NavDropdown title="FORMATIONS"
						id="collasible-nav-dropdown"
						show={show}
						onClick={(e) => handleFormationClick(e)}
						onMouseEnter={showDropdownFormation}
						onMouseLeave={hideDropdownFormation}
					>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-ss3")}>AMIANTE SS3</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-ss4")}>AMIANTE SS4</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-diagnostiqueur")}>DIAGNOSTIQUEUR</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-plomb")}>RISQUE PLOMB</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-aipr")}>AIPR</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-rge")}>RGE</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-coordonateur-sps")}>COORDONNATEUR SPS</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-securite-incendie")}>SÉCURITÉ INCENDIE</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-risque-electrique")}>RISQUE ELECTRIQUE</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-ssi")}>SYSTÈME SÉCURITE INCENDIE</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-travaux-hauteur")}>TRAVAUX EN HAUTEUR</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("formations/formation-secourisme")}>SECOURISME</NavDropdown.Item>
					</NavDropdown>
					<NavDropdown title="MISSIONS"
						id="collasible-nav-dropdown-mission"
						show={showMission}
						onClick={showDropdownMission}
						onMouseEnter={showDropdownMission}
						onMouseLeave={hideDropdownMission}
					>
						<NavDropdown.Item onClick={() => handleClick("missions/expertise-conseil")}>EXPERTISE & CONSEIL</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("missions/assistance-moe")}>ASSISTANCE MAITRISE D'OEUVRE</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("missions/coordination-sps")}>COORDINATION SPS</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("missions/coordination-ssi")}>COORDINATION SSI</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("missions/diagnostic-technique")}>DIAGNOSTIC TECHNIQUE</NavDropdown.Item>
						<NavDropdown.Item onClick={() => handleClick("missions/suivi-projet-telecom")}>SUIVI DE PROJET TÉLÉCOM</NavDropdown.Item>
					</NavDropdown>
					<Nav.Link onClick={() => handleClick("centre-formation")} style={{ textWrap: "nowrap" }}>ORGANISME DE FORMATION</Nav.Link>
					<Nav.Link onClick={() => handleClick("contact")}>CONTACT</Nav.Link>
				</Nav>
				<Nav className='num-end-navbar'>
					<Navbar.Text>
						<a onClick={call} className="phone-text">
							<FontAwesomeIcon icon={faPhone} /> 01 43 93 22 73
						</a>
					</Navbar.Text>
					<Navbar.Text>
						<a href="https://wa.me/33760403446" className="phone-text d-flex flex-row">
							<img src="/external/whatsapp.png" alt="whatsapp" className="whatsapp-icon" />
							07 60 40 34 46
						</a>
					</Navbar.Text>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default MyNavbar;