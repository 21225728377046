import React from 'react';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import HelmetWidget from '../components/helmet'
import '../style.css';
import Avis from '../components/avis';
import Button from 'react-bootstrap/Button';


const CentreFormation = () => {

	function downloadCertificat() {
		window.open("/certificat.pdf", '_blank');
	}

	function downloadCertificatSA() {
		window.open("/certificat_SA.pdf", '_blank');
	}

	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Organisme de formation"
				description="Découvrez notre centre de formation situé à Bobigny. Nous disposons d'infrastructures modernes et d'équipements de pointe pour vous offrir une expérience de formation théorique et pratique complète."
				path="centre-formation"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero" style={{ padding: "5px 15px" }}>
				<span className="page-title">ORGANISME DE FORMATION CERTIFIÉ</span>
			</div>
			<div className='video-centre'>
				<video className='video-accueil' playsInline autoPlay muted loop>
					<source src="/external/chantier.mp4" type="video/mp4" />
					Votre navigateur ne prend pas en charge les vidéos.
				</video>
				<div className='text-centre'>
				Nous sommes fiers de vous annoncer que notre centre de formation détient la précieuse certification Qualibat (télechargeable ci-dessous), gage de notre engagement envers la qualité de nos formations. Reconnues nationalement, ces certifications attestent de notre capacité à dispenser des formations répondant aux exigences les plus élevées en termes de pédagogie, d'organisation et de résultats.
				<br />
				<Button className='mt-2' variant="primary" onClick={downloadCertificat}>Télécharger notre certificat Qualibat</Button>
				<Button className='mt-2 ms-5' variant="primary" onClick={downloadCertificatSA}>Télécharger notre certificat Qualibat - School Amiante</Button>
				</div>
			</div>
			<Avis></Avis>
			<Footer></Footer>
		</div>
	)
}

export default CentreFormation