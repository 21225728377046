import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import '../formations-page.css'
import './missions-page.css'
import HelmetWidget from '../../components/helmet';

const MissionCoordinationSSI = () => {
	var bkgImage = "/images_missions/coordination-ssi.jpg"
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Mission Coordination SSI"
				description="Le rôle du coordonnateur SSI est de définir l'architecture du système de Sécurité Incendie. Sa mission SSI consiste à présider à l’analyse des besoins de mise en sécurité, concevoir et coordonner le système de sécurité dans toutes les phases et avec les maîtres d’œuvre du projet : architecte, Bureaux d’études (BET), contrôleur technique, Coordonnateur SPS…"
				path="missions/mission-coordination-ssi"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero">
				<div className="home-hero1 mission-title-div">
					<div className="home-container01">
						<h1 className="home-hero-heading">
							COORDINATION SSI
						</h1>
						<span className="home-hero-sub-heading bodyLarge">
							La loi impose aux ERP et IGH de faire intervenir le CSSI. Mais, la mission de coordonnateur SSI, globale et complexe, concerne tout établissement qui souhaite mettre en place un système SSI conforme aux normes en vigueur.
						</span>
					</div>
				</div>
			</div>
			<div className='only_mobile image-mission' style={{ backgroundImage: "url(" + bkgImage + ")" }}></div>
			<div className="main-div-mission">
				<Tabs
					defaultActiveKey="a"
					className="tabs-div"
					justify
				>
					<Tab tabClassName='btn-tab-class' eventKey="mission-specialiste" title="Une mission de spécialiste">
						<div className="div-content">
							<ul>
								<li>
									<strong>Définie par des normes</strong> : les normes françaises définissent précisément le cadre du métier de coordonnateur SSI (rôle &amp; missions) dans le chapitre 5.3 de la norme NFS 61-931 d’avril 2004 et les chapitres 14 et 16 de la norme NFS 61-932 de décembre 2008 et les chapitres 4.4 et 12 de la norme NFS 61-970 (Version corrigée) de juillet 2007 (modifié en avril 2009 et novembre 2010). La mission de coordination SSI fait donc partie intégrante des normes SSI.
								</li>
								<li>
									<strong>Obligatoire</strong> : le chapitre 5.3 de la norme NFS 61-931 impose la mission de coordination SSI dès la phase de conception d’un bâtiment : « Une mission de coordination doit nécessairement présider à l'analyse des besoins de sécurité et à la conception du S.S.I. »
								</li>
								<li>
									<strong>Complète et globale</strong>  : elle couvre l’ensemble des phases d’intervention de la maîtrise d’œuvre à savoir la conception, l’exécution et la réception.
								</li>

								<li>
									<strong>Confiée à un professionnel formé aux exigences réglementaires</strong> en matière de sécurité incendie, <strong>normatives, techniques</strong> (liées aux matériels utilisés) et <strong>fonctionnelles</strong> (architecture du bâtiment, besoins de l’utilisateur, modalités d’exploitation du système de sécurité incendie).
								</li>

							</ul>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="contenu-mission" title="Le contenu de la mission">
						<div className="div-content">
							<p>
								Le coordonnateur SSI définit l'architecture du système de Sécurité Incendie.
								Sa mission SSI consiste à présider à l’analyse des besoins de mise en sécurité, concevoir et coordonner le système de sécurité dans toutes les phases et avec les maîtres d’œuvre du projet : architecte, Bureaux d’études (BET), contrôleur technique, Coordonnateur SPS…

							</p>
							<p>
								Une mission en 3 phases :
							</p>
							<p>
								1. <strong>Phase de conception :</strong>
							</p>
							<ul>
								<li>
									Procéder à l’analyse des besoins en tenant compte des exigences réglementaires et normatives : des besoins et de l’organisation de l'exploitant, de la prestation de base de l'installateur, de l’avis de l'organisme d'inspection et de la commission de sécurité.
								</li>
								<li>
									Définir le zonage (zones de détection, zones de mise en sécurité et zones d'alarme).
								</li>
								<li>
									Choisir et organiser le SSI.
								</li>

								<li>
									Concevoir le SSI : le coordonnateur élabore les synopsis et scénarios de mise en sécurité qui présideront à l'élaboration du cahier des charges fonctionnel et constitueront la trame de la procédure des essais de réception.
								</li>


							</ul>
							<p>
								Le coordonnateur SSI participe aux réunions de mise au point technique avec la maîtrise d'œuvre.
							</p>

							<p>
								2. <strong>Phase d’exécution :</strong>
							</p>

							<ul>
								<li>
									Prendre en charge le suivi de l’exécution pour s’assurer de la cohérence technique et fonctionnelle des différents éléments du SSI.
								</li>
								<li>
									S'assurer de la possibilité d'associativité des matériels entre eux en se reportant au procès-verbal de certification (par exemple compatibilité de tension, fonctionnement par rupture ou émission de courant, etc.).
								</li>
								<li>
									Vérifier le respect des règles d’installation : communiquer à la commission de sécurité le dossier technique des équipements (conformément à l'article GE 2 de la réglementation ERP).
								</li>

								<li>
									Créer et mettre à jour le dossier d'identité du SSI tel que visé dans la norme NFS 61-932 (chapitre 14). Le dossier d'identité S.S.I. permettra la réception du S.S.I. par les organismes de contrôle et la commission de sécurité. Le coordonnateur SSI assiste le Maître d’ouvrage lors du passage des commissions de sécurité.
								</li>
							</ul>
							<p>
								3. <strong>Phase de réception :</strong>
							</p>
							<ul>
								<li>
									Procéder à la réception technique du SSI avant la mise en service du matériel : essais de réception technique réalisés par les différentes entreprises.
								</li>
								<li>
									Dresser le Procès verbal correspondant.
								</li>

							</ul>
							<p>
								<strong>En cas de modification et d'extension :</strong></p>
							<p>
								Le cahier des charges fonctionnel ainsi que le dossier d’identité du SSI, évolutifs, devront être mis à jour, dans le respect des points énoncés dans la phase de réalisation ci-dessus.
							</p>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="cdc-fonctionnel" title="Cahier des charges fonctionnel">
						<div className="div-content">
							<p>
								Il comprend, au minimum, les informations suivantes :
							</p>

							<ul>
								<li>
									La catégorie du SSI
								</li>
								<li>
									L'organisation des zones (ZD et ZS)
								</li>
								<li>
									La corrélation entre les ZD et ZS
								</li>
								<li>
									Le positionnement des matériels centraux et déportés éventuels, les modalités de l'exploitation de l'alarme (restreinte, générale, générale sélective)
								</li>
								<li>
									Les alimentations de sécurité (AES, APS) et leurs conditions d'implantation
								</li>
								<li>
									Les constituants du SSI
								</li>
								<li>
									Le principe et la nature des liaisons
								</li>
								<li>
									La procédure de réception technique du SSI.
								</li>

							</ul>

						</div>
					</Tab>
				</Tabs>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default MissionCoordinationSSI;