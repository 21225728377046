
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react';


const HelmetWidget = (props) => {
	var pageTitle = props.pageTitle;
	pageTitle = props.path === "" ? pageTitle : pageTitle + " - Bureau Preventicas";
	return (
		<Helmet>
			<title>{pageTitle}</title>
			<meta name="description" content={props.description} />
			<meta name="keywords" content="bureau preventicas, formation, sécurité, incendie, amiante, ss3, ss4, prévention, risques, professionnels" />
			<meta property="og:title" content={pageTitle + "- Bureau Preventicas"} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content="/favicon2.png" />
			<meta property="og:description" content={props.description} />
			<meta charset="utf-8" />
			<meta http-equiv="X-UA-Compatible" content="IE=edge" />
			<meta property="og:locale" content="fr_FR" />
			<meta property="og:site_name" content="Bureau Preventicas" />
			<meta property='og:url' content={"https://bureau-preventicas-website.vercel.app/" + props.path} />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<link rel="canonical" href={"https://bureau-preventicas-website.vercel.app/" + props.path} />
			<link rel="icon" type="image/x-icon" href="/favicon2.png" />
		</Helmet>
	)
}

HelmetWidget.propTypes = {
	pageTitle: PropTypes.string,
	description: PropTypes.string,
	path: PropTypes.string
}

HelmetWidget.defaultProps = {
	pageTitle: "Bureau Preventicas",
	description: "Bureau Preventicas est un organisme de formation spécialisé dans la prévention des risques professionnels. Nous proposons des formations en sécurité incendie, en premiers secours et en prévention des risques professionnels notamment lié à l'amiante SS3 et SS4.",
	path: ""
}



export default HelmetWidget