import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationAIPR = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation AIPR"
				description="La formation AIPR (Autorisation d'Intervention à Proximité des Réseaux) a pour objectif de doter les participants des connaissances et compétences nécessaires pour intervenir en toute sécurité à proximité des réseaux enterrés. Elle comprend des modules tels que la cartographie, le marquage-piquetage, ainsi que des séances pratiques."
				path="formations/formation-aipr"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION AIPR</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							titre="AIPR CONCEPTEUR"
							nomFormation="AIPR"
							duree="1 jour / 6 heures"
							prix="250 € HT"
							objectif={[
								"Connaître la réglementation liée à l’avis ministériel.",
								"Effectuer les déclarations de projet de travaux (DT)",
								"Analyser les réponses",
								"Procéder ou faire procéder à des investigations complémentaires sur les réseaux situés au droit des travaux",
								"Annexer au dossier de consultation des entreprises puis au marché de travaux les informations utiles sur les réseaux",
								"Procéder ou faire procéder au marquage piquetage des réseaux enterrés",
								"Assurer le suivi ou le contrôle de l’exécution des travaux"
							]}
							preRequis={[
								"Aucun, mais la formation préparatoire à l’examen AIPR - Concepteur est très vivement recommandée pour réussir le QCM de cet examen."
							]}
							participants={[
								"Responsable de projet chargé ayant a pratiqué le guichet unique"
							]}
							programme={[
								"Identifier les rôles, les missions et les responsabilités de chacun",
								"Prescrire les mesures de prévention correspondantes aux risques liés aux réseaux de l’élaboration du projet à la fin de l’exécution des travaux",
								"Engager les procédures adaptées à la spécificité d’un projet",
								"Appliquer les procédures de prévention en amont et en cours de chantier",
								"Vérifier avec l’exécutant des travaux, la prise en compte des consignes lors du démarrage du chantier",
								"Analyser la situation et appliquer la procédure correspondante en cas d’anomalie ou de dommage"
							]}
						/>
						<AccordionBase
							numberKey="1"
							titre="AIPR ENCADRANT"
							nomFormation="AIPR"
							duree="1 jour / 6 heures"
							prix="250 € HT"
							objectif={[
								"Connaître la réglementation liée à l’avis ministériel.",
								"Identifier les différents réseaux.",
								"Définir et mettre en œuvre les moyens de protection collective et individuelle",
								"Appliquer les règles d’arrêt de chantier",
								"Préparer les éléments nécessaires au récolement cartographique"
							]}
							participants={[
								"Personnel n’ayant jamais pratiqué le guichet unique, chargé d’encadrer les chantiers de travaux : conducteur de travaux, chef de chantier, agent de maîtrise d’ouvrage."
							]}
							programme={[
								"L’historique de la réglementation.",
								"Connaître et comprendre le guichet unique.",
								"Les acteurs : responsable de projet, le maître d’ouvrage, le maître d’œuvres…",
								"La cartographie / marquage-piquetage.",
								"Le repérage et les affleurants ainsi que les endommagements des réseaux.",
								"La règle des 4 A, les documents Cerfa."
							]}
						/>
						<AccordionBase
							numberKey="2"
							titre="AIPR OPERATEUR"
							nomFormation="AIPR"
							duree="1 jour / 6 heures"
							prix="250 € HT"
							objectif={[
								"Connaître la réglementation liée à l’avis ministériel.",
								"Identifier les différents réseaux.",
								"Définir et mettre en œuvre les moyens de protection collective et individuelle",
								"Appliquer les règles d’arrêt de chantier",
								"Préparer les éléments nécessaires au récolement cartographique"
							]}
							preRequis={[
								"Aucun, mais la formation préparatoire à l’examen AIPR - Concepteur est très vivement recommandée pour réussir le QCM de cet examen."
							]}
							participants={[
								"Chef d’équipe, Conducteur d’engins, Opérateur, Géomètres, ouvrier…"
							]}
							programme={[
								"Qu’est-ce que la DT, la DICT et l’AIPR ?",
								"Les acteurs : responsable de projet, le maitre d’ouvrage, le maitre d’œuvres…",
								"Les connaissances générales métiers",
								"Le marquage- piquetage",
								"Le repérage et les affleurants ainsi que les endommagements des réseaux",
								"La règle des 4 A"
							]}
						/>
						<AccordionBase
							numberKey="3"
							nomFormation="AIPR"
							titre="EXAMEN AIPR"
							duree="1 heure"
							prix="40 € HT"
							objectif={[
								"Réussir le QCM permettant l’obtention de l’AIPR : Autorisation d’Intervention à Proximité des Réseaux – Concepteur"
							]}
							preRequis={[
								"Aucun, mais la formation préparatoire à l’examen AIPR - Concepteur est très vivement recommandée pour réussir le QCM de cet examen.",
								"Il est conseillé d’être familiarisé avec un environnement informatique basique : ordinateur, clavier, souris, navigateur Internet.",
								"Durée impartie pour répondre à la totalité des 40 questions: 60 mm (1H)",
								"Se munir OBLIGATOIREMENT d’un carte nationale d’identité ou d’un passeport pour se présenter à l’examen."
							]}
							participants={[
								"Responsable de projet chargé :",
								"Effectuer les déclarations de projet de travaux (DT)",
								"Analyser les réponses",
								"Procéder ou faire procéder à des investigations complémentaires sur les réseaux situés au droit des travaux",
								"Annexer au dossier de consultation des entreprises puis au marché de travaux les informations utiles sur les réseaux",
								"Procéder ou faire procéder au marquage piquetage des réseaux situés au droit des travaux",
								"Annexer au dossier de consultation des entreprises puis au marché de travaux les informations utiles sur les réseaux",
								"Procéder ou faire procéder au marquage piquetage des résaux enterrés",
								"Assurer le suivi ou le contrôle de l’exécution des travaux."
							]}
							programme={[
								"Le QCM se compose de 40 questions.",
								"Aucune question n’est éliminatoire.",
								"Chaque question comporte une seule réponse.",
								"10% des questions sont prioritaires, certaines réponses sont soumises à un barème spécifique.",
								"Le QCM se déroule sur un ordinateur connecté à la plateforme QCM du MEDDE via Internet."
							]}
						/>
					</Accordion>
					<FooterFormation
						downloadLink={"AIPR.pdf"}
						nomFormation="AIPR"
					></FooterFormation>
				</div>
				<FormationText
					objectif="La formation AIPR (Autorisation d'Intervention à Proximité des Réseaux) a pour objectif de doter les participants des connaissances et compétences nécessaires pour intervenir en toute sécurité à proximité des réseaux enterrés. Elle comprend des modules tels que la cartographie, le marquage-piquetage, ainsi que des séances pratiques."
					downloadLink="AIPR.pdf"
					nomFormation="AIPR"
					showEvalPeda={false}
					tauxReussite="88 %"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationAIPR