import propTypes from "prop-types"
import '../views/formations-page.css'
import DateTable from "./dateTable"

const FormationText = (props) => {
	const titleFormation = props.downloadLink.split('.')[0].toUpperCase();
	return (
		<div className="text-div">
			<div className="hide-mobile">
				<DateTable formation={titleFormation}/>
				<h5 className="mb-3">Taux de réussite global : {props.tauxReussite}</h5>
				<h4>Objectifs de la formation</h4>
				<span className="home-text14">
					<span>
						{props.objectif}
					</span>
					<br />
					<br />
					{props.isDiagnostiqueur && <span>
						<h4 className="d-flex justify-content-start text-black">Evaluation / Validation</h4>
						<ul>
							<li>Examens écrits</li>
							<li>Examens oraux</li>
							<li>Rédaction de rapports de diagnostic</li>
							<li>L‘assiduité donne droit à la délivrance de l’attestation de formation</li>
							<li>Titre de diagnostiqueur technique immobilier niveau V (Bac+2) (pour le cycle 2)</li>
						</ul>
						<h4 className="d-flex justify-content-start text-black">Pédagogie</h4>
						<ul>
							<li>Diaporama pédagogique interactif</li>
							<li>Photothèque</li>
							<li>Livret stagiaire</li>
							<li>Matériel</li>
							<li>Exercices d’entraînement / QCM d’entraînement</li>
						</ul>
					</span>}
					{!props.isDiagnostiqueur && <span>
						<span className="d-flex justify-content-start">Moyens pédagogiques :</span>
						<ul>
							<li>Salle de cours pouvant accueillir 10 participants</li>
							<li>Salle de pause</li>
							<li>Supports de cours remis aux participants</li>
							<li>Exercices pratiques</li>
							<li>Vidéoprojecteur</li>
						</ul>
					</span>}
					{props.showEvalPeda && <div><span>
						<span className="d-flex justify-content-start">Pédagogie :</span>
						<ul>
							<li> Alternance d’enseignement théorique, de mises en situation des stagiaires, d’études de cas et d’exercices pratiques </li>
						</ul>
					</span>
						<span>
							<span className="d-flex justify-content-start">Evaluation :</span>
							<ul>
								<li> La délivrance de l'attestation de compétence est effectué à l'issue de la réussite de l'évaluation des connaissances : théorique et pratique</li>
							</ul>
						</span></div>}
				</span>
			</div>
		</div>
	)
}

export default FormationText

FormationText.defaultProps = {
	objectif: 'Description des objectifs',
	downloadLink: "RE.pdf",
	nomFormation: "",
	showEvalPeda: true,
	isDiagnostiqueur: true,
	tauxReussite: "88 %"
}

FormationText.propTypes = {
	objectif: propTypes.string,
	downloadLink: propTypes.string,
	nomFormation: propTypes.string,
	isDiagnostiqueur: propTypes.bool,
	showEvalPeda: propTypes.bool,
	tauxReussite: propTypes.string
}