import React from 'react'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import './feature-card.css'

const FeatureCard = (props) => {
	return (
		<div className="featuresCard feature-card-feature-card">
			<FontAwesomeIcon icon={props.icon} className="me-3 mt-1 fa-lg" />
			<div className="feature-card-container">
				<h3 className="feature-card-text heading3 mt-0">{props.heading}</h3>
				<span className="bodySmall">{props.subHeading}</span>
			</div>
		</div>
	)
}

FeatureCard.defaultProps = {
	icon: '',
	heading: '',
	subHeading: '',
}

FeatureCard.propTypes = {
	icon: PropTypes.object,
	heading: PropTypes.string,
	subHeading: PropTypes.string,
}

export default FeatureCard
