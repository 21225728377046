import React from 'react';

import './avis.css';
import ImageWithLoading from './image-loader';
import CardAvis from './card_avis';
import Rating from '@mui/material/Rating';



const Avis = () => {
	return (
		<div id='page-content' className='d-flex flex-row div-avis justify-content-evenly align-items-start w-100 ms-5 me-5 mt-3'>
			<div className='w-100 d-flex justify-content-center flex-column'>
				<h2 className='text-center'>Ils nous ont fait confiance...</h2>
				<ImageWithLoading src="/external/logo_clients.jpg" alt="avis-1" className='logo_clients p-3' />
			</div>
			<div className='vr hide-mobile'></div>
			<hr className='only_mobile divider-avis ms-4' style={{ width: '90%' }} />
			<div className='w-100 d-flex justify-content-center flex-column'>
				<h2 className='text-center mb-3'>...et ils sont satisfaits</h2>
				<span className='d-flex justify-content-center mb-3' style={{ fontStyle: 'italic', color: 'GrayText',fontSize: '3.5vw' }}>
					Note globale <img src='/external/logo-google.png' height={30} width={70} className='ms-2 me-2' /> de 4,7 <Rating name="read-only" value={4.7} readOnly precision={0.1} /> sur 231 avis
				</span>
				<div className='content-avis'>
					<CardAvis
						header="Avis Google"
						content="Très bonne formation, formateur compétent un réel plaisir de faire cette formation."
						footer="Anthony MAZARS"
						note={5}
					/>
					<CardAvis
						header="Avis Google"
						content="Super accueil lors de la formation de l'ensemble de l'équipe. Les formateurs prennent le temps de donner les explications. Centre de formation que je recommande vivement."
						footer="Sidi Ahmed MESSAOUDI"
						note={5}
					/>
					<CardAvis
						header="Avis Google"
						content="Très professionnel, très à l'écoute, tout se passe très bien ! Je trouve que les cours sont bien fait et organisés. Les formateurs sont là pour expliquer correctement quand on en a besoin. Très bon centre."
						footer="Ismael Billy CAMARA"
						note={4}
					/>
					<CardAvis
						header="Avis Google"
						content="Formation de qualité dispensée par un professionnel de terrain très compétent. Service commercial et administratif très agréable et réactif."
						footer="Delphine ROBERTY"
						note={5}
					/>
					<CardAvis
						header="Avis Google"
						content="L'équipe est sympathique et la formation de qualité. Très agréable :)."
						footer="Brenda MOREIRA"
						note={5}
					/>
					<CardAvis
						header="Avis Google"
						content="Une société très sérieuse, dans la prévention du risque; une équipe très dynamique et très pro."
						footer="Arthur PERMENTIER"
						note={5}
					/>
				</div>
			</div>
		</div>
	)
}

Avis.defaultProps = {

}

Avis.propTypes = {

}

export default Avis;