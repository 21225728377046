// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6ChOgwiLa67KQvl59fIpGphnWiROZPlo",
  authDomain: "bp-dateformation.firebaseapp.com",
  projectId: "bp-dateformation",
  storageBucket: "bp-dateformation.appspot.com",
  messagingSenderId: "340772554080",
  appId: "1:340772554080:web:230f8f52c9da39a8930a05",
  measurementId: "G-SX1G26Z01M"
};

// Initialize Firebase
const cong = initializeApp(firebaseConfig);
const analytics = getAnalytics(cong);

export { cong, analytics };  // Export the initialized app and analytics