import React from 'react'
import ReactDOM from 'react-dom/client'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom'
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

import './style.css'
import Contact from './views/contact'
import Formations from './views/formations'
import FormationSS3 from './views/formations/formation-ss3'
import FormationSS4 from './views/formations/formation-ss4'
import FormationPlomb from './views/formations/formation-plomb'
import FormationAIPR from './views/formations/formation-aipr'
import FormationRGE from './views/formations/formation-rge'
import FormationSecuriteIncendie from './views/formations/formation-securite-incendie'
import FormationRisqueElectrique from './views/formations/formation-risque-electrique'
import FormationCoordonateurSPS from './views/formations/formation-coordonateur-sps'
import FormationTravauxHauteur from './views/formations/formation-travaux-hauteur'
import FormationSecourisme from './views/formations/formation-secourisme'
import FormationSSI from './views/formations/formation-ssi'
import FormationDiagnostiqueur from './views/formations/formation-diagnostiqueur'
import MissionDiagnosticTechnique from './views/missions/mission-diagnostic-technique'
import MissionExpertiseConseil from './views/missions/mission-expertise-conseil'
import MissionAssistanceMOE from './views/missions/mission-assistance-moe'
import MissionAssistanceTravauxUrgence from './views/missions/mission-assistance-travaux-urgence'
import MissionCoordinationSPS from './views/missions/mission-coordination-sps'
import MissionCoordinationSSI from './views/missions/mission-coordination-ssi'
import MissionSuiviProjetTelecom from './views/missions/mission-suivi-projet-telecom'
import Home from './views/home'
import CentreFormation from './views/centre-formation'
import NotFound from './views/not-found'

import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
	return (
		<Router>
			<Switch>
				<Route component={Contact} exact path="/contact" />
				<Route component={Formations} exact path="/formations" />
				<Route component={FormationSS3} exact path="/formations/formation-ss3" />
				<Route component={FormationSS4} exact path="/formations/formation-ss4" />
				<Route component={FormationPlomb} exact path="/formations/formation-plomb" />
				<Route component={FormationAIPR} exact path="/formations/formation-aipr" />
				<Route component={FormationRGE} exact path="/formations/formation-rge" />
				<Route component={FormationCoordonateurSPS} exact path="/formations/formation-coordonateur-sps" />
				<Route component={FormationSecuriteIncendie} exact path="/formations/formation-securite-incendie" />
				<Route component={FormationRisqueElectrique} exact path="/formations/formation-risque-electrique" />
				<Route component={FormationDiagnostiqueur} exact path="/formations/formation-diagnostiqueur" />
				<Route component={FormationTravauxHauteur} exact path="/formations/formation-travaux-hauteur" />
				<Route component={FormationSecourisme} exact path="/formations/formation-secourisme" />
				<Route component={FormationSSI} exact path="/formations/formation-ssi" />
				<Route component={MissionDiagnosticTechnique} exact path="/missions/diagnostic-technique" />
				<Route component={MissionExpertiseConseil} exact path="/missions/expertise-conseil" />
				<Route component={MissionAssistanceMOE} exact path="/missions/assistance-moe" />
				<Route component={MissionAssistanceTravauxUrgence} exact path="/missions/assistance-travaux-urgence" />
				<Route component={MissionCoordinationSPS} exact path="/missions/coordination-sps" />
				<Route component={MissionCoordinationSSI} exact path="/missions/coordination-ssi" />
				<Route component={MissionSuiviProjetTelecom} exact path="/missions/suivi-projet-telecom" />
				<Route component={CentreFormation} exact path="/centre-formation" />
				<Route component={Home} exact path="/" />
				<Route component={NotFound} path="**" />
				<Redirect to="**" />
			</Switch>
			<SpeedInsights />
			<Analytics />
		</Router>
	)
}
const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(<App />);