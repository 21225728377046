import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import '../formations-page.css'
import './missions-page.css'
import HelmetWidget from '../../components/helmet';

const MissionCoordinationSPS = () => {
	var bkgImage = "/images_missions/coordination-sps.jpg"
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Mission Coordination SPS"
				description="Pour éviter les accidents et protéger les travailleurs, les Coordonnateurs Sécurité et Protection de la Santé (CSPS) du groupe ont mis en place un système réactif, pour que chaque situation de risque soit traitée sans différé. Anticipation, présence et réactivité sont nos valeurs, pour réduire le risque au minimum."
				path="missions/mission-coordination-sps"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero">
				<div className="home-hero1 mission-title-div">
					<div className="home-container01">
						<h1 className="home-hero-heading">
							COORDINATION SPS
						</h1>
						<span className="home-hero-sub-heading bodyLarge">
							Le maître d’ouvrage est responsable civilement et pénalement de la sécurité des ouvriers qui travaillent, tant en phase chantier que lors des opérations de maintenance en phase exploitation.

							Pour éviter les accidents et protéger les travailleurs, les Coordonnateurs Sécurité et Protection de la Santé (CSPS) du groupe ont mis en place un système réactif, pour que chaque situation de risque soit traitée sans différé.

							Anticipation, présence et réactivité sont nos valeurs, pour réduire le risque au minimum.
						</span>
					</div>
				</div>
			</div>
			<div className='only_mobile image-mission' style={{ backgroundImage: "url(" + bkgImage + ")" }}></div>
			<div className="main-div-mission">
				<Tabs
					defaultActiveKey="a"
					className="tabs-div"
					justify
				>
					<Tab tabClassName='btn-tab-class' eventKey="mission-coordonateur" title="La mission du coordonateur SPS">
						<div className="div-content">
							<p>
								<strong>Conformément au L. 4532-2</strong>
							</p>
							<p>
								Une coordination en matière de sécurité et de santé des travailleurs est organisée pour tout chantier de bâtiment ou de
								génie civil où sont appelés à intervenir plusieurs travailleurs indépendants ou entreprises, entreprises sous-traitantes
								incluses, afin de prévenir les risques résultant de leurs interventions simultanées ou successives et de prévoir, lorsqu'elle
								s'impose, l'utilisation des moyens communs tels que les infrastructures, les moyens logistiques et les protections collectives.
							</p>
							<p>
								<strong>Conformément au R. 4532-1</strong>
							</p>
							<p>
								<strong>Les opérations de bâtiment et de génie civil sont classées en trois catégories :</strong>
							</p>
							<ul>
								<li>
									Première catégorie : opérations soumises à l'obligation de constituer un collège inter entreprises de sécurité, de santé
									et des conditions de travail ; lorsque le chantier doit dépasser un volume de 10 000 hommes-jours et que le nombre d'entreprises,
									travailleurs indépendants et entreprises sous-traitantes inclus, est supérieur à dix s'il s'agit d'une opération de bâtiment
									ou à cinq s'il s'agit d'une opération de génie civil.
								</li>
								<li>
									Deuxième catégorie : sont celles pour lesquelles l'effectif prévisible des travailleurs doit dépasser vingt travailleurs
									à un moment quelconque des travaux et dont la durée doit excéder trente jours ouvrés, ainsi que celles dont le volume prévu
									des travaux doit être supérieur à 500 hommes-jours.
								</li>
								<li>
									Troisième catégorie : opérations soumises à l'obligation d'établir un plan général simplifié de coordination en application
									des articles R. 4532-52 et R. 4532-54 et autres opérations ne relevant pas des première et deuxième catégories.
								</li>
							</ul>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="phase-conception" title="Phase conception">
						<div className="div-content">
							<p>
								<strong>Conformément au R. 4532-12</strong>
							</p>
							<p>
								<strong>Le coordonnateur, au cours de la conception, de l'étude et de l'élaboration du projet de l'ouvrage :</strong>
							</p>
							<ul>
								<li>
									Ouvre un registre-journal de la coordination mentionné à l’article R.4532-38 ;
								</li>
								<li>
									Formule un avis sur les mesures envisagées par le maître d’ouvrage pour intégrer, dès la conception de l’opération, la sécurité
									des interventions ultérieures sur l’ouvrage et consigne cet avis dans le registre journal ;
								</li>
								<li>
									Constitue le dossier d'intervention ultérieure sur l'ouvrage en collaboration avec le maître d’œuvre ;
								</li>
								<li>
									Formule un avis sur le calendrier général de l’opération et consigne cet avis dans le registre journal ;
								</li>
								<li>
									Procède à l’examen des pièces et documents qui lui sont communiqués en application de l’article R. 4532-7 et définit, le
									cas échéant, les contraintes et obligations qui en découlent pour les employeurs ;
								</li>
								<li>
									Définit les contraintes et obligations pour les employeurs relatives à la mise en place et à l'utilisation des protections
									collectives, des appareils de levage, des accès provisoires et des installations générales, notamment les installations électriques.
									Il mentionne dans les pièces écrites leur répartition entre les différents corps d'état ou de métier qui interviendront sur
									le chantier ;
								</li>
								<li>
									Élabore le plan général de coordination lorsqu'il est requis ;
								</li>
								<li>
									Collabore avec le maître d’œuvre à la transcription par celui-ci, sous forme de plan, du projet d’installation de chantier
									;
								</li>
								<li>
									Assure le passage des consignes et la transmission des documents mentionnés aux 1° à 8° au coordonnateur de la phase de
									réalisation de l'ouvrage lorsque celui-ci est différent.
								</li>
							</ul>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="phase-realisation" title="Phase réalisation">
						<div className="div-content">
							<p>
								<strong>Conformément au R. 4532-13</strong>
							</p>
							<p>
								<strong>Le coordonnateur, au cours de la réalisation de l'ouvrage :</strong>
							</p>
							<ul>
								<li>
									Organise entre les entreprises, y compris sous-traitantes, qu'elles se trouvent ou non présentes ensemble sur le chantier,
									la coordination de leurs activités simultanées ou successives,..
								</li>
								<li>
									Il procède avec chacune des entreprises, préalablement à leur intervention, à une inspection commune au cours de laquelle
									sont en particulier précisée les consignes à observer
								</li>
								<li>
									Il analyse et harmonise le plan particulier de sécurité et de protection de la santé lorsque l'entreprise est soumise à
									l'obligation de le rédiger
								</li>
								<li>
									Veille à l'application correcte des mesures de coordination qu'il a définies ainsi que des procédures de travail qui interfèrent
									;
								</li>
								<li>
									Tient à jour et adapte le plan général de coordination et veille à son application ;
								</li>
								<li>
									Complète en tant que de besoin le dossier d'intervention ultérieure sur l'ouvrage.
								</li>
							</ul>

						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="phase-activite" title="Le coordonateur SPS en exploitation">
						<div className="div-content">
							<p>
								<strong>Conformément au R. 4532-14</strong>
							</p>
							<p>
								<strong>Le coordonnateur, au cours d'interférences avec les activités d'exploitation sur le site à l'intérieur ou à proximité duquel est implanté le chantier:</strong>
							</p>
							<ul>
								<li>
									Procède avec le chef de l'établissement en activité, préalablement au commencement des travaux, à une inspection commune
									visant à :
								</li>
								<li>
									Délimiter le chantier ;
								</li>
								<li>
									Matérialiser les zones du secteur dans lequel se situe le chantier qui peuvent présenter des dangers spécifiques pour les
									travailleurs des entreprises appelées à intervenir ;
								</li>
								<li>
									Préciser les voies de circulation que pourront emprunter les travailleurs, les véhicules et engins de toute nature des entreprises
									concourant à la réalisation des travaux, ainsi qu'à définir, pour les chantiers non clos et non indépendants, les installations
									sanitaires, les vestiaires, les locaux de restauration et le local ou les aménagements mentionnés à l'article R. 4534-142-1
									auxquels auront accès leurs travailleurs ;
								</li>
								<li>
									Communique aux entreprises appelées à intervenir sur le chantier les consignes de sécurité arrêtées avec le chef d'établissement
									et, en particulier, celles qu'elles devront donner à leurs travailleurs, ainsi que, s'agissant des chantiers non clos et
									non indépendants, l'organisation prévue pour assurer les premiers secours en cas d'urgence et la description du dispositif
									mis en place à cet effet dans l'établissement.
								</li>
							</ul>
						</div>
					</Tab>
				</Tabs>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default MissionCoordinationSPS;