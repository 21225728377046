import React from 'react';

import PropTypes from 'prop-types'
import ImageWithLoading from './image-loader';

const PartenaireCard = (props) => {
	return (
		<div className="home-step">
			<ImageWithLoading alt={props.partenaireName} src={props.logoPart} className="partenaire-image" />
		</div>
	)
}

PartenaireCard.defaultProps = {
	partenaireName: 'Partenaire',
	partenaireDescription: 'Description du partenaire',
	logoPart: 'https://via.placeholder.com/150'
}

PartenaireCard.propTypes = {
	partenaireName: PropTypes.string,
	partenaireDescription: PropTypes.string,
	logoPart: PropTypes.string
}

export default PartenaireCard;