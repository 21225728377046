import React from 'react'


import FAQRow from '../components/faq-row'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import FormContact from '../components/form'
import './contact.css'

import HelmetWidget from '../components/helmet'

const Contact = () => {
	return (
		<div className="contact-container">
			<HelmetWidget
				pageTitle="Contact"
				description="Contactez Bureau Preventicas pour plus d'informations sur nos formations en amainte, sécurité incendie, en premiers secours et en prévention des risques professionnels."
				path="contact"
			>
			</HelmetWidget>
			<Navbar></Navbar>
			<div className="contact-container1">
				<div className="contact-container2">
					<h1 className="contact-text page-title">Une question ? Contactez-nous</h1>
					<FormContact></FormContact>
				</div>
				<div className="contact-faq">
					<div className="contact-faq-container faqContainer">
						<div className="contact-faq1">
							<div className="contact-container5">
								<span>FAQ</span>
								<h2 className="heading2">Questions fréquentes</h2>
							</div>
							<div className="contact-container6">
								<FAQRow
									answer="Bureau Preventicas offre des formations en santé et sécurité au travail, en premiers secours et en prévention des risques professionnels."
									question="Quels sont les types de formations offertes par Bureau Preventicas?"
								></FAQRow>
								<FAQRow
									answer="Bureau Preventicas propose des formations de qualité dispensées par des professionnels expérimentés, des infrastructures modernes et un suivi personnalisé pour chaque apprenant ainsi qu'un diplôme certifié par Certibat et Qualiopi."
									question="Quels sont les avantages de choisir Bureau Preventicas pour sa formation en sécurité?"
								></FAQRow>
								<FAQRow
									answer="Pour vous inscrire à une formation chez Bureau Preventicas, vous pouvez remplir le formulaire de contact sur notre site web ou nous contacter directement par téléphone."
									question="Comment puis-je m'inscrire à une formation chez Bureau Preventicas?"
								></FAQRow>
								<FAQRow
									answer="Vous pouvez nous contacter en remplissant le formulaire de contact sur notre site web, en nous appelant au numéro indiqué ou en venant directement à notre centre de formation situé au 22 avenue de la Division Leclerc 93000 Bobigny."
									question="Comment puis-je contacter Bureau Preventicas pour plus d'informations?"
								></FAQRow>
								<FAQRow
									answer="En tant que particulier, vous pouvez financer votre formation grâce à France Travail (Pôle Emploi), à votre CPF ou avec une aide de la région. En tant qu'entreprise, vous pouvez bénéficier d'une prise en charge par votre OPCO (Constructys, OPCO Atlas, AFDAS, etc...)."
									question="Comment puis-je financer une formation chez Bureau Preventicas?"
								></FAQRow>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default Contact
