import React from 'react'

import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

import './not-found.css'

const NotFound = () => {
	const history = useHistory()
	return (
		<div className="not-found-container">
			<Helmet>
				<title>404 - Page non trouvée</title>
			</Helmet>
			<h3>OOPS! Cette page n'existe pas</h3>
			<div className="not-found-container1">
				<h1 className="not-found-text1">404</h1>
			</div>
			<div className="not-found-container2">
				<h2 className="not-found-text2">
					Vous essayez d'accéder à une page qui n'existe pas. Veuillez vérifier l'URL ou retourner à la page d'accueil.
				</h2>
				<button className="not-found-button" onClick={() => history.push('/')}>Retour à la page d'accueil</button>
			</div>
		</div>
	)
}

export default NotFound
