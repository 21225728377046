import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';
import FooterFormation from '../../components/footer-formation';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';

const FormationCoordonateurSPS = () => {
	// Ajouter numéro de téléphone
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Coordonnateur SPS"
				description="La formation CSPS vise à doter les participants des connaissances et compétences nécessaires pour exercer la fonction de Coordonnateur en matière de Sécurité et de Protection de la Santé, avec de niveau 1 à 3 et nécessitant une réactualisation tous les 5 ans."
				path="formations/formation-coordonateur-sps"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION COORDONNATEUR SPS</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<p style={{ textAlign: "center",  fontSize: "1.1rem", marginBottom: "1rem" }}>
						Pour les candidatures à la formation Coordonnateur SPS uniquement, nous contacter au 07 80 97 75 75.
					</p>
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							titre="CSPS - Niveau 1"
							nomFormation="CSPS"
							duree="entre 20 et 24 jours (selon modules)"
							prix="entre 3 790 et 3 990€ HT (selon modules)"
							objectif={[
								"Mettre en œuvre le dispositif de coordination SPS sur les opérations de niveau 1 phase conception et réalisation",
								"Exercer la mission de Coordonnateur de Sécurité et de Protection de la Santé en ayant obtenu l’attestation de compétence obligatoire (conception et/ou réalisation)."
							]}
							participants={[
								"Toute personne physique justifiant d’une expérience professionnelle d’une durée minimale de 5 ans :",
								"En architecture, ingénierie ou maîtrise d’œuvre, pour la phase de conception, d’étude et d’élaboration du projet de l’ouvrage ;",
								"En matière de contrôle des travaux, d’ordonnancement, de pilotage et de conduite des travaux ou de maîtrise de chantier, ou en tant que Coordonnateur ou Agent en matière de sécurité, pour la phase de réalisation de l’ouvrage."
							]}
							preRequis={["Justifier d’une expérience professionnelle d’une durée minimale de 5 ans : en architecture,ingénierie ou maîtrise d’œuvre, pour le suivi de la phase de conception, d’étude et d’élaboration du projet de l’ouvrage ; et/ou en matière de contrôle des travaux, d’ordonnancement, de pilotage et de conduite des travaux ou de maîtrise de chantier, ou en tant que Coordonnateur ou Agent en matière de sécurité, pour la phase de réalisation de l’ouvrage."]}
						></AccordionBase>
						<AccordionBase
							numberKey="1"
							titre="CSPS - Niveau 2"
							nomFormation="CSPS"
							duree="entre 18 et 22 jours (selon modules)"
							prix="entre 3 290 et 3 590€ HT (selon modules)"
							objectif={[
								"Mettre en œuvre le dispositif de coordination SPS sur les opérations de niveau 2 phase conception et réalisation",
								"Exercer la mission de Coordonnateur de Sécurité et de Protection de la Santé en ayant obtenu l’attestation de compétence obligatoire (conception et/ou réalisation)."
							]}
							participants={[
								`Tout professionnel souhaitant exercer la mission de Coordinateur SPS sur les opérations de
								niveau 2 (phase réalisation) et justifiant des prérequis réglementaires, définis dans l'arrêté du
								26/12/2012.`
							]}
							preRequis={[
								`Justifier d’une expérience professionnelle d’une durée minimale de 5 ans : d’étude et
								d’élaboration du projet de l’ouvrage ; et/ou en matière de contrôle des travaux,
								d’ordonnancement, de pilotage et de conduite des travaux ou de maîtrise de chantier, ou en tant
								que Coordonnateur ou Agent en matière de sécurité, pour la phase de réalisation de l’ouvrage
								`
							]}
							programme={[
								"Le cadre législatif et réglementaire des opérations du bâtiment et du génie civil",
								"Le cadre et les conditions de l’action du Coordonnateur",
								"La prévention des risques professionnels",
								"La coordination de conception",
								"La coordination d’exécution"
							]}
						></AccordionBase>
						<AccordionBase
							numberKey="2"
							titre="CSPS - Niveau 3"
							nomFormation="CSPS"
							duree="14 jours / 98 heures"
							prix="2 590€ HT"
							objectif={[
								"Mettre en œuvre le dispositif de coordination SPS sur les opérations de niveau 3 phase conception et réalisation",
								"Exercer la mission de Coordonnateur de Sécurité et de Protection de la Santé en ayant obtenu l’attestation de compétence obligatoire (conception et/ou réalisation)."
							]}
							participants={[
								"Tout professionnel souhaitant exercer la mission de Coordinateur SPS sur les opérations de niveau 3 (phase de conception et/ou réalisation) et justifiant des prérequis réglementaires, définis dans l'arrêté du 26/12/2012."
							]}
							preRequis={[
								"En phase conception : avoir une expérience professionnelle en architecture, ingénierie ou maîtrise d'œuvre d'une durée minimale de 3 ans pour accéder à ce Tronc Commun de niveau 3 ou de disposer d'un diplôme de niveau au moins égal à la licence en architecture ou dans le domaine de la construction, du bâtiment et des travaux publics ou de la prévention des risques professionnels.",
								`En phase réalisation : avoir une expérience professionnelle en matière de contrôle des travaux,
								d'ordonnancement, de pilotage et de conduite des travaux ou de maîtrise de chantier, ou en tant
								que coordonnateur ou agent en matière de sécurité, d'une durée minimale de 3 ans pour accéder
								à ce Tronc Commun de niveau 3 ou de disposer d'un diplôme de niveau au moins égal à la licence
								en architecture ou dans le domaine de la construction, du BTP ou de la prévention des risques
								professionnels`
							]}
							programme={[
								"Le cadre législatif et réglementaire des opérations du bâtiment et du génie civil",
								"Le cadre réglementaire",
								"La prévention des risques professionnels",
								"Le registre journal",
								"Le DIUO",
								"Le contrat"
							]}
						>
						</AccordionBase>
						<AccordionBase
							numberKey="3"
							titre="Actualisation CSPS"
							nomFormation="CSPS"
							duree="5 jours / 35 heures"
							prix="1 490€ HT"
							objectif={[
								"Poursuivre vos missions de coordonnateur SPS suite à l’actualisation de vos compétences",
								"Obtenir le renouvellement de vos attestations de compétences pour une durée de 5 ans et de conseiller la maîtrise d’ouvrage sur la mise en œuvre des nouvelles dispositions"
							]}
							preRequis={[
								"Être titulaire d’une attestation de compétence de coordonnateur SPS niveau 1, 2 ou 3 en cours de validité",
							]}
							programme={[
								"Réactualiser les connaissances nécessaires à l’exercice de la fonction de CSPS",
								"Obtenir l’attestation de compétences CSPS réactualisée niveau 1 (conception et/ou réalisation)",
								"Alternance d’exposés théoriques et d’études de cas :",
								"L’expérience des stagiaires",
								"L’évolution réglementaire",
								"L’évolution de la phase de conception",
								"Les fondamentaux et les écrits du CSPS",
								"Les risques spécifiques (chimiques, toxiques, plomb, amiante, légionellose)",
								"L’analyse des risques chantier"
							]}
						></AccordionBase>
					</Accordion>
					<FooterFormation 
						nomFormation="CSPS"
						downloadLink={"CSPS.pdf"}
					></FooterFormation>
				</div>
				<FormationText
					objectif="La formation CSPS vise à doter les participants des connaissances et compétences nécessaires pour exercer la fonction de Coordonnateur en matière de Sécurité et de Protection de la Santé, avec des niveaux allant de 1 à 3, couvrant les phases de conception et/ou réalisation, et nécessitant une réactualisation tous les 5 ans."
					downloadLink="CSPS.pdf"
					tauxReussite="93 %"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationCoordonateurSPS
