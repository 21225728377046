import { useState } from 'react';


function ImageWithLoading(props) {
	const [loaded, setLoaded] = useState(false);
	return (
		<div className='w-100 d-flex justify-content-center'>
			{!loaded ? (
				<svg className={props.className} viewBox="0 0 100 100">
					<rect height='100%' width='100%' rx="10" ry="10" fill="#CCC" />
				</svg>
			) : null}
			<img
				src={props.src}
				alt={props.alt}
				className={props.className}
				style={!loaded ? { visibility: 'hidden' } : {}}
				onLoad={() => setLoaded(true)}
			/>
		</div>
	);
}

export default ImageWithLoading;