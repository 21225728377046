import React from 'react'

import Navbar from '../components/navbar'
import FormationPageCard from '../components/formation-page-card'
import Footer from '../components/footer'
import './formations.css'
import HelmetWidget from '../components/helmet'

const Formations = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formations"
				description="Découvrez nos formations en travaux liés à l'amiante, en sécurité incendie, en premiers secours, en prévention des risques professionnels et en travaux en hauteur. Bureau Preventicas vous propose des formations de qualité dispensées par des professionnels expérimentés, des infrastructures modernes et un suivi personnalisé pour chaque apprenant."
				path="formations"
			>
			</HelmetWidget>
			<Navbar></Navbar>
			<div className="formations-container1">
				<span className="formations-text page-title">SANTÉ ET SÉCURITÉ AU TRAVAIL</span>
			</div>
			<div className="formations-container2">
				<FormationPageCard
					title="Amiante SS3"
					description="Acquérez une expertise complète dans la gestion sécuritaire des interventions de retrait ou d'encapsulage de materiaux contenant de l'amiante en suivant notre formation SS3."
					path="formation-ss3"
					image="/images_formation/ss3.jpg"
				></FormationPageCard>
				<FormationPageCard
					title="Amiante SS4"
					description="Explorez la formation SS4 pour acquérir les compétences nécessaires à la gestion sécurisée des travaux de maintenance, de réparation et d'entretien impliquant de l'amiante."
					path="formation-ss4"
					image="/images_formation/ss4.jpg"
				></FormationPageCard>
				<FormationPageCard
					title="Diagnostiqueur"
					description="Devenez un expert en diagnostic immobilier avec notre formation spécialisée. Apprenez à réaliser des diagnostics amiante, plomb, termites, gaz, électricité, DPE, ERNMT, etc."
					path="formation-diagnostiqueur"
					image="/images_formation/diagnostic.jpg"
				></FormationPageCard>
				<FormationPageCard
					title="Risque Plomb"
					description="Maîtrisez les compétences nécessaires pour réaliser des diagnostics plomb et gérer les travaux de retrait de plomb en suivant notre formation technique spécialisée."
					path="formation-plomb"
					image="/images_formation/plomb.png"
				></FormationPageCard>
				<FormationPageCard
					title="Coordonnateur SPS"
					description="Formez-vous en tant que CSPS et apprenez à mettre en place des stratégies de prévention, assurant un environnement de travail sécurisé et conforme aux normes."
					path="formation-coordonateur-sps"
					image="/images_formation/csps.png"
				></FormationPageCard>
				<FormationPageCard
					title="Coordinateur SSI"
					description="Devenez un expert en sécurité incendie avec nos formations spécialisées. Apprenez la coordination SSI ou encore la réglementation incendie ERP-IGH"
					path="formation-ssi"
					image="/images_formation/ssi.jpg"
				></FormationPageCard>
				<FormationPageCard
					title="Sécurité Incendie"
					description="Maîtrisez les compétences spécialisées nécessaires en prévention, détection et lutte contre l'incendie avec notre formation technique."
					path="formation-securite-incendie"
					image="/images_formation/si.png"
				></FormationPageCard>
				<FormationPageCard
					title="Risque Electrique"
					description="Boostez votre sécurité avec cette formation. Adaptée aux non-électriciens, elle offre des connaissances approfondies et des pratiques dynamiques."
					path="formation-risque-electrique"
					image="/images_formation/re.png"
				></FormationPageCard>
				<FormationPageCard
					title="Secourisme"
					description="Soyez prêt à intervenir en situation d'urgence avec nos formations de secourisme et de manutention manuelle.Maîtrisez les gestes d'urgence, la réanimation cardio-respiratoire."
					path="formation-secourisme"
					image="/images_formation/secourisme.jpg"
				></FormationPageCard>
				<FormationPageCard
					title="Travaux en Hauteur"
					description="Optimisez vos compétences en travaux en hauteur avec nos formations spécialisées. Explorez la gestion et le port des harnais de sécurité pour travailler en toute sécurité."
					path="formation-travaux-hauteur"
					image="/images_formation/th.png"
				></FormationPageCard>
				<FormationPageCard
					title="AIPR"
					description="Explorez notre formation AIPR pour une intervention sûre près des réseaux enterrés. Nos modules englobent la cartographie, le marquage-piquetage, et des séances pratiques pour une maîtrise complète."
					path="formation-aipr"
					image="/images_formation/aipr.jpg"
				></FormationPageCard>
				<FormationPageCard
					title="RGE"
					description="Devenez un professionnel certifié RGE avec notre formation spécialisée. Apprenez à réaliser des audits énergétiques et à conseiller sur les travaux de rénovation énergétique."
					path="formation-rge"
					image="/images_formation/rge.png"
				></FormationPageCard>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default Formations
