import React from 'react'

import PropTypes from 'prop-types'

import './formation-page-card.css'
import ImageWithLoading from './image-loader';
import { useHistory } from "react-router-dom";


const FormationPageCard = (props) => {

	const history = useHistory();
	function handleClick(path) {
		history.push("/formations/" + path);
		// Scroll to top
		window.scrollTo(0, 0);
	}

	const redirectPage = props.path;

	return (
		<div className="formation-page-card-container" onClick={() => handleClick(redirectPage)}>
			<div className="formation-page-card-blog-post-card">
				<ImageWithLoading alt="image" src={props.image} className="formation-page-card-image" />
				<div className="formation-page-card-container1">
					<span className="formation-page-card-text1">
						{props.title}
					</span>
					<span className="formation-page-card-text2 hide-mobile">
						{props.description}
					</span>
				</div>
			</div>
		</div>
	)
}

FormationPageCard.defaultProps = {
	title: '',
	description: "",
	path: "formation-ss3",
	image: "https://images.unsplash.com/photo-1547841243-eacb14453cd9?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIyfHxjaXR5fGVufDB8fHx8MTYyNjE4NjYxMg&amp;ixlib=rb-1.2.1&amp;w=1400",
}

FormationPageCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	path: PropTypes.string,
	image: PropTypes.string,
}

export default FormationPageCard
