import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import '../formations-page.css'
import './missions-page.css'
import HelmetWidget from '../../components/helmet';

const MissionSuiviProjetTelecom = () => {
	var bkgImage = "/images_missions/suivi-projet-telecom.jpg"
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Mission Suivi de projet telecom"
				description="Bénéficiez d'un accompangement dans la gestion de projet, la coordination technique (Conception, Travaux, assistance MO, MOC et MOE…) et la recherche et négociation de sites. Déploiement des infrastructures de réseaux mobiles (France et Export pour le compte des opérateurs, équipementiers et sous-traitants)."
				path="missions/mission-suivi-projet-telecom"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero">
				<div className="home-hero1 mission-title-div">
					<div className="home-container01">
						<h1 className="home-hero-heading">
							SUIVI DE PROJET TÉLÉCOM
						</h1>
						<span className="home-hero-sub-heading bodyLarge">
							Nous accompagnons nos partenaires dans la gestion de projet, la coordination technique (Conception, Travaux, assistance MO, MOC et MOE…) et la recherche et négociation de sites.
							La société Bureau Preventicas intervient pour: le déploiement des infrastructures de réseaux mobiles (France et Export pour le compte des opérateurs, équipementiers et sous-traitants).
						</span>
					</div>
				</div>
			</div>
			<div className='only_mobile image-mission' style={{ backgroundImage: "url(" + bkgImage + ")" }}></div>
			<div className="main-div-mission">
				<Tabs
					defaultActiveKey="a"
					className="tabs-div"
					justify
				>
					<Tab tabClassName='btn-tab-class' eventKey="a" title="Suivi de projet telecom">
						<div className="div-content">
							<h2>
								SUIVI DE TRAVAUX
							</h2>
							<ul>
								<li>
									Conseil &amp; audit des dossiers APS, APD et du choix des équipements
								</li>
								<li>
									Gestion de la relation avec les bureaux d'études pour l'analyse de structure, de résistance....
								</li>
								<li>
									Gestion de commandes, planification, suivi budgétaire et réalisation des chantiers.
								</li>
								<li>
									Coordination entre les divers intervenants techniques / Choix et pilotage des sous-traitants.
								</li>
								<li>
									Mise en place et respect des consignes de sécurités établies
								</li>
								<li>
									Gestion de planning / suivi des délais.
								</li>
								<li>
									Conduite des réunions de chantiers et établissement du reporting.
								</li>
								<li>
									Planification et supervision de la réception de sites en présence des différents intervenants / levée de réserves éventuelles.
								</li>
								<li>
									Rédaction des DOE - DIUO / Remise du dossier de transfert au client comportant l'ensemble des documents techniques ainsi
									que les pièces administratives liées à la réalisation du site
								</li>
								<li>
									Vérification des points d'ancrage
								</li>
							</ul>
						</div>
					</Tab>
				</Tabs>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default MissionSuiviProjetTelecom;