import React from 'react'

import Navbar from '../components/navbar'
import FormationPageCard from '../components/formation-page-card'
import FeatureCard from '../components/feature-card'
import Footer from '../components/footer'
import './home.css'
import PartenaireCard from '../components/partenaires'
import { faBook, faBuildingCircleCheck, faHeadset, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons'

import HelmetWidget from '../components/helmet'
import CarouselImage from '../components/carousel'

const Home = () => {
	return (
		<div className="home-container">
			<HelmetWidget
				pageTitle="Bureau Preventicas - Formations en sécurité"
				description="Bureau Preventicas est un organisme de formation spécialisé dans la prévention des risques professionnels. Nous proposons des formations en sécurité incendie, en premiers secours et en prévention des risques professionnels notamment lié à l'amiante SS3 et SS4.">
			</HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero">
				<div className="heroContainer">
					<CarouselImage></CarouselImage>
					<div className='background-image hide-mobile'></div>
				</div>
			</div>
			<div className="home-features">
				<h1 className="home-text11">
					<span>Agir ensemble, c'est prévenir !</span>
				</h1>
				<h2 className="home-text14 hide-mobile">
					Dans l'univers des formations Bureau Preventicas, l'expertise se conjugue avec l'innovation pour
					vous offrir des programmes de prévention adaptés, façonnant ainsi des environnements professionnels
					plus sûrs et performants.
				</h2>
				<div className="home-container02">
					<FormationPageCard
						title="Amiante SS3"
						description="Acquérez une expertise complète dans la gestion sécuritaire des interventions de retrait ou d'encapsulage de materiaux contenant de l'amiante en suivant notre formation SS3."
						path="formation-ss3"
						image="/images_formation/ss3.jpg"
					></FormationPageCard>
					<FormationPageCard
						title="Amiante SS4"
						description="Explorez la formation SS4 pour acquérir les compétences nécessaires à la gestion sécurisée des travaux de maintenance, de réparation et d'entretien impliquant de l'amiante."
						path="formation-ss4"
						image="/images_formation/ss4.jpg"
					></FormationPageCard>
					<FormationPageCard
						title="Diagnostiqueur"
						description="Devenez un expert en diagnostic immobilier avec notre formation spécialisée. Apprenez à réaliser des diagnostics amiante, plomb, termites, gaz, électricité, DPE, ERNMT, etc."
						path="formation-diagnostiqueur"
						image="/images_formation/diagnostic.jpg"
					></FormationPageCard>
					<FormationPageCard
						title="Risque Plomb"
						description="Maîtrisez les compétences nécessaires pour réaliser des diagnostics plomb et gérer les travaux de retrait de plomb en suivant notre formation technique spécialisée."
						path="formation-plomb"
						image="/images_formation/plomb.png"
					></FormationPageCard>
					<FormationPageCard
						title="Coordonnateur SPS"
						description="Formez-vous en tant que CSPS et apprenez à mettre en place des stratégies de prévention, assurant un environnement de travail sécurisé et conforme aux normes."
						path="formation-coordonateur-sps"
						image="/images_formation/csps.png"
					></FormationPageCard>
					<FormationPageCard
						title="Coordinateur SSI"
						description="Devenez un expert en sécurité incendie avec nos formations spécialisées. Apprenez la coordination SSI ou encore la réglementation incendie ERP-IGH"
						path="formation-ssi"
						image="/images_formation/ssi.jpg"
					></FormationPageCard>
					<FormationPageCard
						title="Sécurité Incendie"
						description="Maîtrisez les compétences spécialisées nécessaires en prévention, détection et lutte contre l'incendie avec notre formation technique."
						path="formation-securite-incendie"
						image="/images_formation/si.png"
					></FormationPageCard>
					<FormationPageCard
						title="Risque Electrique"
						description="Boostez votre sécurité avec cette formation. Adaptée aux non-électriciens, elle offre des connaissances approfondies et des pratiques dynamiques."
						path="formation-risque-electrique"
						image="/images_formation/re.png"
					></FormationPageCard>
					<FormationPageCard
						title="Secourisme"
						description="Soyez prêt à intervenir en situation d'urgence avec nos formations de secourisme et de manutention manuelle.Maîtrisez les gestes d'urgence, la réanimation cardio-respiratoire."
						path="formation-secourisme"
						image="/images_formation/secourisme.jpg"
					></FormationPageCard>
					<FormationPageCard
						title="Travaux en Hauteur"
						description="Optimisez vos compétences en travaux en hauteur avec nos formations spécialisées. Explorez la gestion et le port des harnais de sécurité pour travailler en toute sécurité."
						path="formation-travaux-hauteur"
						image="/images_formation/th.png"
					></FormationPageCard>
					<FormationPageCard
						title="AIPR"
						description="Explorez notre formation AIPR pour une intervention sûre près des réseaux enterrés. Cartographie, marquage-piquetage, séances pratiques, tout y est pour une maîtrise complète"
						path="formation-aipr"
						image="/images_formation/aipr.jpg"
					></FormationPageCard>
					<FormationPageCard
						title="RGE"
						description="Devenez un professionnel certifié RGE avec notre formation spécialisée. Apprenez à réaliser des audits énergétiques et à conseiller sur les travaux de rénovation énergétique."
						path="formation-rge"
						image="/images_formation/rge.png"
					></FormationPageCard>
				</div>
			</div>
			<div className="home-features1">
				<div className="featuresContainer">
					<div className="home-features2">
						<div className="home-container03">
							<h2 className="heading2">NOS COMPÉTENCES</h2>
							<span className="home-features-sub-heading bodyLarge">
								Découvrez ce qui fait de Bureau Preventicas le choix idéal
								pour votre formation en sécurité.
							</span>
						</div>
						<div className="home-container04">
							<FeatureCard
								icon={faBook}
								heading="Formations certifiées Qualiopi"
								subHeading="Formations professionnelles et certifiées pour garantir la sécurité de vos équipes."
							></FeatureCard>
							<FeatureCard
								icon={faPersonChalkboard}
								heading="Formateurs expérimentés"
								subHeading="Formateurs issus du batiment, expérimentés pour transmettre leur savoir-faire et expertise."
							></FeatureCard>
							<FeatureCard
								icon={faBuildingCircleCheck}
								heading="Plateforme pédagogique"
								subHeading="Locaux equipés pour simuler des chantiers fictifs, pour des formations en conditions réelles, pratiques et interactives."
							></FeatureCard>
							<FeatureCard
								icon={faHeadset}
								heading="Suivi personnalisé"
								subHeading="Accompagnement individualisé pour chaque participant afin de garantir une progression optimale."
							></FeatureCard>
						</div>
					</div>
				</div>
			</div>
			<div className="home-steps">
				<h2 className="home-text34">NOS PARTENAIRES</h2>
				<span className="home-text35">
					<span>Fiers de collaborer avec des acteurs engagés pour l'excellence professionnelle.</span>
					<br></br>
					<span></span>
				</span>
				<div className="home-container05">
					<PartenaireCard
						partenaireName="CPF"
						partenaireDescription="Le Compte Personnel de Formation est un dispositif de financement de formation professionnelle, permettant à nos apprenants de financer tout ou partie de leur formation."
						logoPart="/external/cpf.png"
					></PartenaireCard>
					<PartenaireCard
						partenaireName="Constructys"
						partenaireDescription="Acteur majeur dans le financement des formations individuelles, le FONGECIF accompagne nos apprenants en facilitant l'accès à des cursus de qualité, contribuant ainsi à leur évolution professionnelle."
						logoPart="/external/constructys.png"
					></PartenaireCard>
					<PartenaireCard
						partenaireName="AGEFIPH"
						partenaireDescription="Partenaire financier dédié à l'inclusion, facilitant l'accès aux formations pour tous, y compris les personnes en situation de handicap."
						logoPart="/external/logo-agefiph.png"
					></PartenaireCard>
					<PartenaireCard
						partenaireName="France Travail"
						partenaireDescription=" Partenaire financier engagé dans la promotion de l'emploi, facilitant l'accès à nos formations."
						logoPart="/external/logo-francetravail.png"
					></PartenaireCard>
					{/* <PartenaireCard
						partenaireName="Fongecif"
						partenaireDescription="Acteur majeur dans le financement des formations individuelles, le FONGECIF accompagne nos apprenants en facilitant l'accès à des cursus de qualité, contribuant ainsi à leur évolution professionnelle."
						logoPart="/external/logo-fongecif.png"
					></PartenaireCard> */}
				</div>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default Home
