import Card from 'react-bootstrap/Card';
import Rating from '@mui/material/Rating';

const CardAvis = (props) => {
	return (
		<Card>
			<Card.Body>
				<p style={{ fontSize: '15px' }}>
					{' '}{props.content}{' '}
				</p>
				<footer className="blockquote-footer">
					{props.footer}
					<Rating name="read-only" value={props.note} readOnly />
				</footer>
			</Card.Body>
		</Card>
	);
}

export default CardAvis;