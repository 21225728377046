import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { checkInput } from '../formValidator';


import React from 'react';
import "../style.css"

function FormContact() {
	const [validated, setValidated] = useState(false);

	const handleSubmit = (event) => {
		var form = event.target;
		var formFields = event.target.elements;
		setValidated(true);

		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			event.preventDefault();
			switch (formFields["type_demande"].value) {
				case "0":
					var formation = formFields["formation_concernee"].value;
					var valueFromSelect = "Demande d'information sur une formation : " + formation;
					break;
				case "1":
					var valueFromSelect = "Comptabilité";
					break;
				case "2":
					var valueFromSelect = "Autre";
					break;
				default:
					var valueFromSelect = "Autre";
					break;
			}
			var templateParams = {
				nom: formFields["nom"].value,
				prenom: formFields["prenom"].value,
				email: formFields["email"].value,
				numero: formFields["numero"].value,
				societe: formFields["societe"].value,
				type: valueFromSelect,
				message: formFields["message"].value
			};

			emailjs.init('XBD6WIhY8PLVhOE9x');
			emailjs.send('service_c8lqn9d', 'template_zdfn4ou', templateParams)
				.then(
					() => {
						Swal.fire({
							title: 'Message envoyé',
							text: "Votre message a bien été envoyé, nous vous répondrons dans les plus brefs délais.",
							icon: 'success',
							confirmButtonText: 'Ok'
						});
						form.reset();
						setValidated(false);
					},
					(error) => {
						Swal.fire({
							title: 'Erreur',
							text: "Une erreur est survenue lors de l'envoi du message, veuillez réessayer.",
							icon: 'error',
							confirmButtonText: 'Ok'
						});
					},
				);
		}
	};

	const onChangeSelect = (e) => {
		var select = e.target;
		var value = select.options[select.selectedIndex].value;
		if (value == 0) {
			document.getElementById('field_formation').classList.remove("d-none");
		} else {
			document.getElementById('field_formation').classList.add("d-none");
		}
	}

	return (
		<Form noValidate validated={validated} onSubmit={handleSubmit} className="w-100">
			<div className="contact-form">
				<div id="column-fields" className="me-5">
					<Form.Group className="mb-3">
						<Form.Label>Nom  <span className="text-danger">*</span></Form.Label>
						<Form.Control name='nom' required type="text" placeholder="Nom" />
						<Form.Control.Feedback type="invalid">
							Veuillez entrer un nom.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Prénom  <span className="text-danger">*</span></Form.Label>
						<Form.Control name='prenom' required type="text" placeholder="Prénom" />
						<Form.Control.Feedback type="invalid">
							Veuillez entrer un prénom.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Email <span className="text-danger">*</span></Form.Label>
						<Form.Control name='email' required type="text" placeholder="Email" />
						<Form.Control.Feedback type="invalid">
							Veuillez entrer un email valide.
						</Form.Control.Feedback>
					</Form.Group>


					<Form.Group className="mb-3">
						<Form.Label>Numéro  <span className="text-danger">*</span></Form.Label>
						<Form.Control name='numero' required type="text" placeholder="Numéro de téléphone" onKeyDown={checkInput} />
						<Form.Control.Feedback type="invalid">
							Veuillez entrer un numéro de téléphone.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Societé</Form.Label>
						<Form.Control name='societe' type="text" placeholder="Sociéte" />
					</Form.Group>
				</div>
				<div id="column-msg" className="ms-5 me-3">
					<Form.Group className="mb-3">
						<Form.Label>Sujet <span className="text-danger">*</span></Form.Label>
						<Form.Select name='type_demande' onChange={onChangeSelect}>
							<option value="0">Demande d'information sur une formation</option>
							<option value="1">Comptabilité</option>
							<option value="2">Autre</option>
						</Form.Select>
					</Form.Group>
					<Form.Group id='field_formation' className="mb-3">
						<Form.Label>Formation concernée <span className="text-danger">*</span></Form.Label>
						<Form.Select name='formation_concernee'>
							<option>Amiante SS3</option>
							<option>Amiante SS4</option>
							<option>Coordonnateur SPS</option>
							<option>Sécurité Incendie</option>
							<option>Risque Electrique</option>
							<option>Système Sécurité Incendie</option>
							<option>Travaux en Hauteur</option>
							<option>Secourisme</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Message  <span className="text-danger">*</span></Form.Label>
						<Form.Control name='message' required as="textarea" rows={6} cols={60} placeholder="Message" />
						<Form.Control.Feedback type="invalid">
							Veuillez entrer un message.
						</Form.Control.Feedback>
					</Form.Group>
					<div className="d-flex justify-content-center mt-3 mb-3">
						<button className="btn btn-download" type="submit">
							Envoyer
						</button>
					</div>
				</div>
				<div>
					<iframe src="https://maps.google.com/maps?width=100%25&height=600&hl=fr&q=+(Office%20Preventicas)&t=&z=19&ie=UTF8&iwloc=B&output=embed" height={400}></iframe>
				</div>
			</div>
		</Form>
	);
}

export default FormContact;