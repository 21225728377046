import Carousel from 'react-bootstrap/Carousel';
import ImageWithLoading from './image-loader';

import React from 'react';

const CarouselImage = () => {
	return (
		<Carousel data-bs-theme="dark" className='only_mobile h-fit-content'>
			<Carousel.Item>
				<ImageWithLoading src="/centre_formation/globe_carousel.jpg" alt="photo-centre-0" className="w-100" />
			</Carousel.Item>
			<Carousel.Item>
				<ImageWithLoading src="/centre_formation/carousel-1.jpg" alt="photo-centre-1" className="w-100" />
			</Carousel.Item>
			<Carousel.Item>
				<ImageWithLoading src="/centre_formation/carousel-2.jpg" alt="photo-centre-2" className="w-100" />
			</Carousel.Item>
			<Carousel.Item>
				<ImageWithLoading src="/centre_formation/carousel-3.jpg" alt="photo-centre-3" className="w-100" />
			</Carousel.Item>
			<Carousel.Item>
				<ImageWithLoading src="/centre_formation/carousel-7.jpg" alt="photo-centre-7" className="w-100" />
			</Carousel.Item>
			<Carousel.Item>
				<ImageWithLoading src="/centre_formation/carousel-4.jpg" alt="photo-centre-4" className="w-100" />
			</Carousel.Item>
			<Carousel.Item>
				<ImageWithLoading src="/centre_formation/carousel-5.jpg" alt="photo-centre-5" className="w-100" />
			</Carousel.Item>
		</Carousel>
	);
}

export default CarouselImage;