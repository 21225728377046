import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationSSI = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Système Sécurité Incendie"
				description="La formation Système sécurité incendie (SSI) vise à doter les participants des connaissances et compétences nécessaires pour exceller dans la prévention, la détection et l'intervention en cas d'incendie."
				path="formations/formation-ssi"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION SYSTÈME SÉCURITÉ INCENDIE</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							nomFormation="SSI"
							titre="Réglementation Incendie ERP-IGH"
							duree="2 jours / 14 heures"
							prix="790 € HT"
							objectif={[
								"Acquérir une instruction technique spécialisée en matière de prévention, de détection et de lutte contre l’incendie, ainsi que d’entretien des moyens de secours."
							]}
							participants={[
								"Personnes engagées dans un parcours de coordinateur SSI.",
								"Personnes chargées de la sécurité incendie dans des ERP non soumis à la qualification SSIAP.",
								"Gestionnaires de bâtiment en charge de l’application du règlement de sécurité incendie.",
								"Toute autre personne souhaitant acquérir des connaissances en sécurité incendie en ERP, IGH, ICPE."
							]}
							programme={[
								"Contexte réglementaire : Notions de droit du travail, hygiène, sécurité et conditions de travail, responsabilité des différents acteurs.",
								"Définition des bâtiments Code du Travail et ERP : Les textes réglementaires applicables, procédures administratives, lecture de plans, comportement au feu des matériaux, approche de la vulnérabilité d’un bâtiment.",
								"Les installations techniques et spécifiques à la sécurité : Les différents systèmes d’alarme, les SSI, les installations de désenfumage.",
								"Management de la sécurité incendie : Rôle des commissions de sécurité, rôle et mission du chef de service de sécurité incendie en ERP, études de cas sur dossier."
							]}
							pedagogie="Enseignement théorique et études de cas pratiques | Participants : maximum 12"
							evaluationValidation="À l’issue du contrôle continu, délivrance d’une attestation de formation par le Ministère du Travail."
						></AccordionBase>
						<AccordionBase
							numberKey="21"
							titre="Principe de Mise en Sécurité du SSI"
							nomFormation="SSI"
							duree="2 jours / 14 heures"
							prix="790 € HT"
							objectif={[
								"Comprendre et maîtriser le fonctionnement du Système de Sécurité Incendie (SSI) de l’établissement.",
								"Connaître et adopter le bon comportement lors de chaque phase : alarme, alerte, extinction, mise en sécurité et évacuation.",
								"Connaître et assimiler les consignes de sécurité."
							]}
							participants={[
								"Tout le personnel des établissements dotés d’un SSI et susceptible d’avoir à intervenir sur ce système de sécurité incendie.",
								"Personnes engagées dans un parcours de coordinateur SSI."
							]}
							programme={[
								"Enseignement théorique : Les mécanismes du feu, l’incendie (conduite à tenir, réflexes à adopter), les consignes de sécurité, rappels réglementaires, principes généraux de fonctionnement d’un SSI (alarme, alerte, mise en sécurité des personnes, évacuation), les classes de feu, les différents types d’extincteurs à utiliser.",
								"Mise en pratique : Découverte pratique du SSI, exercices simulés, exercices sur le SSI de l’établissement.",
								"Évaluation : QCM de contrôle des connaissances des stagiaires, évaluation pratique des stagiaires à l’utilisation et à la maîtrise du SSI."
							]}
							pedagogie="Courts exposés théoriques pour un maximum de pratique sur l’équipement SSI | Participants : maximum 4"
							evaluationValidation="Délivrance d’une attestation de formation aux stagiaires ayant réussi le QCM d’évaluation et les exercices pratiques sur le SSI."
						></AccordionBase>
						<AccordionBase
							numberKey="2"
							titre="Coordonnateur en Systèmes de Sécurité Incendie"
							nomFormation="SSI"
							duree="5 jours / 35 heures"
							prix="1490 € HT"
							objectif={[
								"Connaître les applications et obligations pour les SSI.",
								"Identifier le cadre de la mission du coordonnateur SSI.",
								"Identifier les tâches du coordonnateur SSI en phase de conception et de réalisation pour en maîtriser son déroulement en liaison avec les autres intervenants."
							]}
							participants={[
								"Ingénieurs et techniciens de maintenance.",
								"Maîtres d’ouvrages, maîtres d’œuvre, bureaux d’étude et d’ingénierie, entreprises installatrices, services travaux neufs et d’entretien des établissements en exploitation, coordinateurs SSI.",
								"Chefs de service des établissements recevant du public."
							]}
							preRequis={[
								"Connaître les règlements de sécurité des établissements recevant du public (ERP) et des immeubles de grande hauteur (IGH) sur justificatif, ou avoir suivi le stage Fondamentaux de la sécurité en ERP et IGH.",
								"Bon niveau de culture générale.",
								"Les pré-requis sont validés par une évaluation initiale."
							]}
							programme={[
								"Le cadre réglementaire et opérationnel du rôle du coordonnateur SSI dans une opération de construction.",
								"Les SSI.",
								"Les normes SSI, la règle R7 de l’APSAD et leurs applications.",
								"La mission du coordonnateur - Phase conception : établissement du cahier des charges fonctionnel du SSI (catégorie du SSI, organisation des zones, définition des liaisons...).",
								"La mission du coordonnateur - Phase réalisation : suivi de chantier, cohérence technique, constitution du dossier d’identité SSI, réception technique."
							]}
							pedagogie="Formation théorique où les obligations réglementaires sont abordées à l’aide de missions déjà réalisées | Participants : maximum 12"
							evaluationValidation="À l’issue du contrôle continu, délivrance d’une attestation de formation par le Ministère du Travail."
						></AccordionBase>
					</Accordion>
					<FooterFormation
						nomFormation="Système Sécurité Incendie"
						downloadLink="SSI.pdf"
					></FooterFormation>
				</div>
				<FormationText
					objectif="
					La formation SSI (Agent de Sécurité Incendie) vise à doter les participants des compétences nécessaires pour exceller dans la prévention, la détection et l'intervention en cas d'incendie. Structurée en niveaux, elle garantit une expertise évolutive de l'initiation à l'expertise. Un suivi régulier assure la mise à jour des connaissances pour une performance continue et une sécurité optimale sur le terrain."
					downloadLink="SSI.pdf"
					nomFormation="Système Sécurité Incendie"
					tauxReussite="89 %"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationSSI