import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationRisqueElectrique = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Risque Electriques"
				description="La formation Risque Électrique a pour finalité de doter les participants des connaissances et compétences indispensables pour occuper le poste d'Exécutant Électrique. Structurée sur plusieurs niveaux, elle aborde les aspects de réglementation, de sécurité électrique, et de mesures de protection."
				path="formations/formation-risque-electrique"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION RISQUE ELECTRIQUES</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="0">
						<AccordionBase
							numberKey="0"
							titre="Habilitation Électrique - Exécutants"
							nomFormation="Risque Electrique"
							duree="1 jour / 7 heures"
							prix="190 € HT"
							objectif={[
								"Acquérir une connaissance de la réglementation en matière d’instructions et consignes de sécurité électrique et des risques présentés par le courant électrique.",
								"Adapter les connaissances acquises aux travaux non électriques pour travailler dans les zones d’environnement électrique.",
								"Permettre à l’employeur de délivrer à son personnel un titre d’habilitation Exécutant B0 / H0 / H0V."
							]}
							participants={[
								"Toute personne chargée de réaliser des opérations d’ordre non électrique dans des zones à risques électriques."
							]}
							preRequis={[
								"Aucune connaissance en électricité n’est obligatoire pour suivre cette formation.",
							]}
							programme={[
								"Connaissances de l’électricité, de ses dangers et des mesures de protection :",
								"Les risques de l’utilisation des matériels et outillages électriques.",
								"Connaissances de l’habilitation électrique :",
								"Les limites, zones d’environnement et opérations liées.",
								"Les équipements de protection individuelle et collective.",
								"Les définitions des différentes opérations.",
								"La consignation électrique et la mise en sécurité.",
								"Le rôle et les limites des opérations pour le personnel exécutant non électricien.",
								"Travaux pratiques :",
								"Repérer une zone de travail.",
								"Repérer les différents acteurs et lire un titre d’habilitation.",
								"Suivre les prescriptions de sécurité ainsi que la bonne utilisation des matériels et outillages électriques.",
								"Appliquer les prescriptions et les instructions de sécurité."
							]}
							pedagogie="Formation théorique comportant des travaux pratiques et des mises en situation permettant d’acquérir rigueur et bonnes pratiques | Participants : maximum 12"
							evaluationValidation={[
								"Évaluation des connaissances par quiz dynamique avec des télécommandes radio.",
								"Évaluation du savoir-faire par travaux pratiques et/ou jeux de rôles."
							]}
						></AccordionBase>
					</Accordion>
					<FooterFormation
						nomFormation="Risque Electrique"
						downloadLink={"RE.pdf"}
					></FooterFormation>
				</div>
				<FormationText
					objectif="
					La formation Risque Électrique a pour finalité de doter les participants des connaissances et compétences indispensables pour occuper le poste d'Exécutant Électrique. Structurée sur plusieurs niveaux, de l'initiation à l'expertise, elle aborde les aspects de réglementation, de sécurité électrique, et de mesures de protection."
					downloadLink="RE.pdf"
					nomFormation="Risque Electrique"
					tauxReussite="88 %"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationRisqueElectrique