import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import '../formations-page.css'
import './missions-page.css'
import HelmetWidget from '../../components/helmet';

const MissionAssistanceMOE = () => {
	var bkgImage = "/images_missions/assistance-maitrise-oeuvre.jpg"
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Mission Assistance Maitrise d'oeuvre"
				description="L'objectif est d'accompagner nos clients pendant les phases d'études, d'exécution, de coordination générale des essais, et de la mise en service des équipements d'installation complexes en leur faisant bénéficier de notre savoir-faire pour plus d'efficacité dans leur mission. Nous l'exerçons dans le cadre de missions et grands projets, et mettons en place des méthodes assurant la maitrise complète des opérations."
				path="missions/mission-assistance-moe"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero">
				<div className="home-hero1 mission-title-div">
					<div className="home-container01">
						<h1 className="home-hero-heading">
							ASSISTANCE <br className='only_mobile' /> MAITRISE D'OEUVRE
						</h1>
						<span className="home-hero-sub-heading bodyLarge">
							L'objectif est d'accompagner nos clients pendant les phases d'études, d'exécution, de coordination générale des essais, et de la mise en service des équipements d'installation complexes en leur faisant bénéficier de notre savoir-faire pour plus d'efficacité dans leur mission.
							Nous l'exerçons dans le cadre de missions et grands projets, et mettons en place des méthodes assurant la maitrise complète des opérations, depuis la phase d'étude jusqu'aux essais.
						</span>
					</div>
				</div>
			</div>
			<div className='only_mobile image-mission' style={{ backgroundImage: "url(" + bkgImage + ")" }}></div>
			<div className="main-div-mission">
				<Tabs
					defaultActiveKey="a"
					className="tabs-div"
					justify
				>
					<Tab tabClassName='btn-tab-class' eventKey="assistance" title="Assistance MOE de centres et de moyens">
						<div className="div-content">
							<p>
								Cette activité de conseil s'exerce dès la phase amont du projet, avec l'étude approfondie de son environnement technologique
								et industriel, jusqu'à la mise en service.
							</p>
							<p>
								Elle inclut l'analyse globale du centre d'essais, bâtiments et moyens techniques, ainsi que la mise en route opérationnelle
								des moyens et des équipes.
							</p>
							<p>
								Bureau PREVENTICAS aide ses clients à réaliser, ou réalise pour eux :
							</p>
							<ul>
								<li>
									Les études de faisabilité,
								</li>
								<li>
									L'évaluation budgétaire et le plan de développement,
								</li>
								<li>
									L'expression des besoins,
								</li>
								<li>
									L'écriture du cahier des charges fonctionnel,
								</li>
								<li>
									L'établissement des dossiers d'appel d'offres,
								</li>
								<li>
									L'établissement et le suivi du dossier de contrôle des interfaces,
								</li>
								<li>
									La sélection des industriels,
								</li>
								<li>
									L'analyse des dossiers de conception et de réalisation lors de revues formelles,
								</li>
								<li>
									Le suivi de réalisation, y compris le suivi de chantier,
								</li>
								<li>
									L'établissement des plans de recette et de maintenance,
								</li>
								<li>
									Le suivi des essais de validation et l'approbation des résultats,
								</li>
								<li>
									L'établissement des procédures d'exploitation et de maintenance,
								</li>
								<li>
									La formation du personnel.
								</li>
							</ul>
						</div>
					</Tab>
					<Tab tabClassName='btn-tab-class' eventKey="assistanceAmiante" title="Assistance maitrise d'oeuvre amiante">
						<div className="div-content">
							<p>
								Nous proposons d’assister nos clients d’une manière globale sur la problématique amiante dans le cadre de leurs projets.
							</p>
							<p>
								Cette assistance peut être de plusieurs niveaux : du simple conseil sur les spécificité technique en matière d’amiante,
								au suivi du retrait des matériaux contenant de l’amiante.
							</p>
							<p>
								Chaque chantier et chaque mission sont spécifiques. Nous établissons ensemble un partenariat qui répond à vos besoin et
								à vos attentes.
							</p>
							<p>
								Ce type de mission peut être jumelé avec une mission d’expertise et de recherche de matériaux susceptibles de contenir de
								l’amiante en préalable à une réhabilitation ou à une déconstruction.
							</p>
							<p>
								A l’issue de nos investigations, il vous sera remis un rapport de repérage clair et détaillé.
							</p>
							<p>
								De même, nous nous inscrivons dans une démarche de partenariat globale avec nos clients, prolongeant notre mission bien
								au delà du repérage lorsque cela s’avère nécessaire.
							</p>
							<p>
								Nous vous assistons en phase chantier vis-à-vis des autorités sur des questions comme l’exhaustivité des repérages, la friabilité
								des matériaux ou sur la légitimité de certaines demandes d’investigations complémentaires par exemple.
							</p>
						</div>
					</Tab>
				</Tabs>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default MissionAssistanceMOE;