import React from 'react'

import PropTypes from 'prop-types'

import './faq-row.css'

const FAQRow = (props) => {
	return (
		<div className="faq-row-container">
			<span className="faq-row-text heading3">{props.question}</span>
			<span className="bodySmall">{props.answer}</span>
		</div>
	)
}

FAQRow.propTypes = {
	question: PropTypes.string,
	answer: PropTypes.string,
}

export default FAQRow
